import React from 'react';
import styled from 'styled-components';
import {Route} from "react-router-dom";
import A from "../scenes/static/seo/A";
import B from "../scenes/static/seo/B";
import C from "../scenes/static/seo/C";
import D from "../scenes/static/seo/D";

const SEORoutes = [
        <Route
        path="/courrier-en-ligne"
        exact
        render={props => <A {...props} />}
        key={1}
        />,
        <Route
        path="/courrier-certifie"
        exact
        render={props => <B {...props} />}
        key={2}
        />,
        <Route
        path="/envoi-courrier-certifie"
        exact
        render={props => <C {...props} />}
        key={3}
        />,
        <Route
        path="/acccuse-reception"
        exact
        render={props => <D {...props} />}
        key={4}
        />];

export default SEORoutes;