import React, { useContext, useState } from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ResetForm from "./ResetForm";
import { Row } from "../Grid";
import VerificationForm from "./VerificationForm";
import api from "../../utils/api";
import { UserContext } from "../../contexts/user";
import { useToasts } from "react-toast-notifications";

const SignupLoginWrapper = styled.div``;

export const SIGNUP = "SIGNUP",
  LOGIN = "LOGIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  VERIFICATION = "VERIFICATION";

const SignupLogin = ({ onSignup, onLogin, errors, defaultState = LOGIN, ...props }) => {
  const [type, setType] = useState(defaultState);
  const [verificationData, setVerificationData] = useState(null);
  const { setUser } = useContext(UserContext);
  const { addToast } = useToasts();

  const createUser = async () => {
    try {
      const rep = await api.post("/users", verificationData);
      if (rep.id) {
        setUser(rep);
        onSignup();
      } else {
        console.error("Erreur lors de la création de l'utilisateur", rep);
      }
    } catch (err) {
      console.error("Erreur lors de la création de l'utilisateur", err);
    }
  };

  const logUser = async (userData) => {
    setUser(userData);
    onLogin(userData);
  };

  return (
    <SignupLoginWrapper>
      <Row sm>
        <div>
          {type === LOGIN && (
            <div>
              <h1 style={{ marginTop: 50 }}>Connexion</h1>
              <p className={"subtitle"}>
                Pas encore de compte ? <a onClick={() => setType(SIGNUP)}>Inscrivez-vous</a>
              </p>
              <LoginForm onSuccess={logUser} />
              <a onClick={() => setType(RESET_PASSWORD)}>J'ai oublié mon mot de passe</a>
            </div>
          )}
          {type === SIGNUP && (
            <div>
              <h1>Inscription</h1>
              <p className={"subtitle"}>
                Déjà inscrit ? <a onClick={() => setType(LOGIN)}>Connectez-vous</a>
              </p>
              <SignupForm
                onSuccess={(data) => {
                  setVerificationData({ ...data, destination: data.telMobile });
                  setType(VERIFICATION);
                  addToast("Un code de vérification vous a été envoyé par SMS.", { appearance: "success" });
                }}
                mobilePlaceholer={"Téléphone mobile (obligatoire)"}
              />
            </div>
          )}
          {type === RESET_PASSWORD && (
            <div>
              <h1>Réinitialisez votre mot de passe</h1>
              <p className={"subtitle"}>
                Déjà inscrit ? <a onClick={() => setType(LOGIN)}>Connectez-vous</a>
              </p>
              <ResetForm />
            </div>
          )}
        </div>
        {type === VERIFICATION && (
          <div>
            <h1>Authentification SMS</h1>
            <p className={"subtitle"}>Entrez le code de vérification à 6 chiffres que vous avez reçu par SMS.</p>
            <VerificationForm destination={verificationData && verificationData.destination} onSuccess={createUser} />
          </div>
        )}
        <div className={"hidden-md"}>
          <img src={require("assets/img/ill/1.png")} alt="" />
        </div>
      </Row>
    </SignupLoginWrapper>
  );
};

export default SignupLogin;
