import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import BaseBlock from "../components/BaseBlock";
import Checkbox from "../components/Checkbox";
import { Button, Form } from "reactstrap";
import InputGroup from "../components/InputGroup";
import { Row } from "../components/Grid";
import { VerificationContext } from "../contexts/verification";
import api from "../utils/api";
import ErrorText from "../components/ErrorText";
import { validateFrenchPhoneNumber } from "../utils/phoneValidation";

const AcceptedWrapper = styled(Container)``;

export default function CodeSend({ ...props }) {
  const [type, setType] = useState("");
  const [sms, setSms] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { setDestination } = useContext(VerificationContext);

  const checkAccept = async () => {
    if (props.match.params.recipient_id && props.match.params.recipient_token) {
      let recipient = await api.get(`/recipients/${props.match.params.recipient_id}/${props.match.params.recipient_token}`);

      if (recipient.status === "ACCEPTED") {
        props.history.push(`/reco/accept/${props.match.params.recipient_id}/${props.match.params.recipient_token}`);
      }
    }
  };

  useEffect(() => {
    checkAccept();
  }, []);

  const handleChangeSms = (phone) => {
    setType("sms");
    setSms(phone);
    validateFrenchPhoneNumber(phone, setPhoneError);
  };

  const handleChangeEmail = (e) => {
    setEmail(e);
    setType("mail");
  };

  const goEnter = async (e) => {
    props.history.push(`/reco/code/enter/${props.match.params.recipient_id}/${props.match.params.recipient_token}`);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === "sms" && phoneError) {
      setError("Veuillez corriger le numéro de téléphone avant de continuer.");
      return;
    }

    const destination = type === "sms" ? sms : email;
    let rep = await api.post("/users/verifications", { destination });
    if (rep && rep.ok == 1) {
      setDestination(destination);
      goEnter();
    } else {
      setError("Erreur. Veuillez vérifier les informations.");
    }
  };

  return (
    <AcceptedWrapper>
      <h1>Authentifiez-vous</h1>
      <p>
        Choisissez un moyen d'authentification pour confirmer votre identité et recevoir votre email avec horodatage certifié
        eIDAS.
      </p>
      <Form onSubmit={handleSubmit}>
        <BaseBlock>
          <div>
            <Checkbox onChange={() => setType("sms")} value={type === "sms"}>
              SMS
            </Checkbox>
            <BaseBlock t={2}>
              <Row>
                <div>
                  <InputGroup
                    onChangeText={(e) => handleChangeSms(e)}
                    inputType={"tel"}
                    placeholder={"Téléphone mobile"}
                    value={sms}
                    name={"sms"}
                    error={phoneError}
                  />
                </div>
                <div></div>
              </Row>
            </BaseBlock>
          </div>
        </BaseBlock>
        <BaseBlock>
          <div>
            <Checkbox onChange={() => setType("mail")} value={type === "mail"}>
              Adresse e-mail
            </Checkbox>
            <BaseBlock t={2}>
              <Row>
                <InputGroup
                  value={email}
                  onChangeText={(e) => handleChangeEmail(e)}
                  type="email"
                  placeholder={"Email"}
                  icon={"fa fa-envelope-o"}
                  name={"email"}
                />
                <div></div>
              </Row>
            </BaseBlock>
          </div>
          <ErrorText>{error ? error : <span>&nbsp;</span>}</ErrorText>
        </BaseBlock>
        <div className="form-group">
          <Button right type={"submit"}>
            Continuer
          </Button>
        </div>
      </Form>
    </AcceptedWrapper>
  );
}
