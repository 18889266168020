import React from "react";
import styled from "styled-components";
import { FormGroup, Input, InputGroup as IG, InputGroupAddon, InputGroupText } from "reactstrap";
import ErrorText from "./ErrorText";
import PhoneInput from "react-phone-number-input";

const InputGroupWrapper = styled.div``;

const InputGroup = ({
	error,
	value,
	onChangeText,
	placeholder,
	inputType = "text",
	name = false,
	icon = false,
	onFocus,
	onBlur,
	...props
}) => {
	return (
		<InputGroupWrapper>
			<FormGroup className={`${error ? "has-danger" : ""} mb-3`}>
				<IG className="input-group">
					{icon && (
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className={`${icon}`} />
							</InputGroupText>
						</InputGroupAddon>
					)}
					{inputType === "tel" ? (
						<PhoneInput
							placeholder={placeholder}
							value={value}
							onChange={onChangeText}
							{...props}
							defaultCountry="FR"
						/>
					) : (
						<Input
							placeholder={placeholder}
							type={inputType}
							value={value}
							onChange={(e) => onChangeText(e.target.value)}
							{...(name ? { name } : {})}
							onFocus={onFocus}
							onBlur={onBlur}
							{...props}
						/>
					)}
				</IG>
				<ErrorText>{error ? error : <span>&nbsp;</span>}</ErrorText>
			</FormGroup>
		</InputGroupWrapper>
	);
};

export default InputGroup;
