import React, { useState } from "react";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import BaseBlock from "../../components/BaseBlock";
import useElements from "../../utils/useElement";
import CheckAuth from "../../components/blocks/CheckAuth";
import { readableDate } from "../../utils/utils";
import { Badge, Card, CardBody, Modal, ModalBody } from "reactstrap";
import { colors } from "../../utils/constants";
import api, { API_BASE_URL } from "../../utils/api";
import { Helmet } from "react-helmet/es/Helmet";
import moment from "moment";
import ModalHeader from "reactstrap/es/ModalHeader";

const MailHistory = ({ ...props }) => (
  <CheckAuth>
    <Helmet>
      <title>Mes courriers envoyés</title>
      <meta
        name={"description"}
        content={"Mes courriers envoyés Mail-certificate, l'envoi de mail recommandé avec horodatage certifié eIDAS"}
      />
    </Helmet>
    <MailHistoryContent {...props} />
  </CheckAuth>
);

const MailHistoryContent = ({ ...props }) => {
  const [emails = [], loaded] = useElements("/emails", { extend: ["recipients", "attachments"] });
  const [timestampToken, setTimestampToken] = useState(false);

  const _display = (recipient) => {
    window.location = `${API_BASE_URL}/recipients/${recipient.id}/pdf`;
  };

  const _displayTimestampToken = async (recipient) => {
    const token = await api.get(`${API_BASE_URL}/recipients/${recipient.id}/timestamptokens`);
    if (!token.error_message) {
      setTimestampToken(token.token);
    }
  };

  return (
    <CheckAuth>
      <Modal isOpen={timestampToken} toggle={() => setTimestampToken(false)}>
        <ModalHeader>Jeton d'horodatage qualifié Certigna</ModalHeader>
        <ModalBody>
          <div style={{ overflowWrap: "break-word" }}>{timestampToken}</div>
        </ModalBody>
      </Modal>

      <Helmet>
        <title>Mes courriers envoyés</title>
        <meta
          name={"description"}
          content={"Mes courriers envoyés Mail-certificate, l'envoi de mail recommandé avec horodatage certifié eIDAS."}
        />
      </Helmet>
      <Container>
        <Section top>
          <h1>Mes emails envoyés</h1>
          {loaded &&
            emails.map((email) => (
              <BaseBlock t={2} b={2} key={email.id}>
                <Card>
                  <CardBody>
                    <span className={"lead"}>{readableDate(email.createdAt)}</span>
                    {!!email.attachments.length && (
                      <div>
                        <div>
                          <b>Pièces jointes</b>
                        </div>
                        {email.attachments.map((attachment) => (
                          <div key={attachment.id} style={{ marginLeft: 20 }}>
                            <a onClick={() => (window.location = `${API_BASE_URL}/attachments/${attachment.id}`)}>
                              {attachment.name}
                            </a>
                          </div>
                        ))}
                        <small>
                          <i>(Pour consulter la pièce jointe, cliquez sur le lien ci-dessus)</i>
                        </small>
                      </div>
                    )}
                    {email.recipients.map((recipient) => (
                      <BaseBlock key={recipient.id} t={3} b={3}>
                        <b>
                          {recipient.contact.prenom} {recipient.contact.nom} {recipient.contact.societe}{" "}
                          <small>{recipient.contact.email}</small>
                        </b>
                        <br />
                        <a style={{ color: colors.primary }} onClick={() => _display(recipient)}>
                          <StatusBadge recipient={recipient} /> &nbsp;<small>Référence : {recipient.reference}</small>
                        </a>
                        <br />
                        <small>
                          <i>(Pour consulter le courrier, cliquez sur le lien ci-dessus)</i>
                        </small>
                        {email.createdAt > "2023-05-23" && (
                          <div>
                            <br />
                            <br />
                            <a onClick={() => _displayTimestampToken(recipient)}>
                              Télécharger le jeton d'horodatage qualifié Certigna
                            </a>
                          </div>
                        )}
                      </BaseBlock>
                    ))}
                  </CardBody>
                </Card>
              </BaseBlock>
            ))}
        </Section>
      </Container>
    </CheckAuth>
  );
};

export default MailHistory;

const StatusBadge = ({ recipient, ...props }) => {
  let text = "",
    color = "";

  let { status, updatedAt } = recipient;

  switch (status) {
    case "CREATED":
      text = "Non envoyé";
      color = "primary";
      break;
    case "SENT":
      text = "Envoyé, non lu";
      color = "primary";
      break;
    case "ACCEPTED":
      text = `Lu le ${moment(updatedAt).format("DD/MM/YYYY HH:mm")}`;
      color = "success";
      break;
    case "DECLINED":
      text = `Refusé le ${moment(updatedAt).format("DD/MM/YYYY HH:mm")}`;
      color = "danger";
      break;
    case "EXPIRED":
      text = `Expiré le ${moment(updatedAt).format("DD/MM/YYYY HH:mm")}`;
      color = "danger";
      break;
  }

  return <Badge color={color}>{text}</Badge>;
};
