import React from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";

import Section from "../components/Section";
import SignupLogin from "../components/blocks/SignupLogin";
import { Helmet } from "react-helmet/es/Helmet";

const LoginWrapper = styled(Container)``;

const Login = ({ ...props }) => {
  return (
    <LoginWrapper>
      <Helmet>
        <title>Connexion à Mail-Certificate l'envoi de mail recommandé avec horodatage certifié eIDAS</title>
        <meta
          name={"description"}
          content={
            "Connectez vous à Mail-Certificate, le service d'envoi de mail recommandé avec horodatage certifié eIDAS en ligne"
          }
        />
      </Helmet>
      <Section top>
        <SignupLogin onLogin={() => props.history.push("/")} onSignup={() => props.history.push("/")} />
      </Section>
    </LoginWrapper>
  );
};

export default Login;
