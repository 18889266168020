export const isNumberEmptyOrJustIndicator = (phone) => {
  return !phone || phone === "+33" || phone === "+" || phone === undefined;
};

export const isValidFrenchNumber = (phone) => {
  if (!phone.startsWith("+33")) return true;
  let nationalNumber = phone.startsWith("+330") ? phone.slice(4) : phone.slice(3);
  return /^(6|7)/.test(nationalNumber);
};

export const isValidPhoneNumberLength = (phone) => {
  return phone.startsWith("+33") && phone.length === 12;
};

export const validateFrenchPhoneNumber = (phone, setErrorCallback) => {
  if (isNumberEmptyOrJustIndicator(phone)) {
    setErrorCallback(phone ? "" : "Le numéro de téléphone ne peut pas être vide.");
    return false;
  }

  if (!isValidFrenchNumber(phone)) {
    setErrorCallback("Numéro de téléphone mobile français invalide. Doit commencer par 06 ou 07.");
    return false;
  }

  if (!isValidPhoneNumberLength(phone)) {
    setErrorCallback("Numéro de téléphone mobile français invalide. Doit contenir 10 chiffres.");
    return false;
  }

  setErrorCallback("");
  return true;
};
