import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import Section from "../components/Section";
import Container from "reactstrap/es/Container";
import { Row } from "../components/Grid";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import BaseBlock from "../components/BaseBlock";
import { colors } from "../utils/constants";
import { UserContext } from "../contexts/user";
import { EmailContext } from "../contexts/email";
import Button from "../components/Button";
import { STANDARD_PRICE, PLUS_PRICE } from "../contexts/email";
import { UncontrolledTooltip } from "reactstrap";

const ShadowedImg = styled.img`
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const HowToItem = styled.div`
  text-align: center;
`;

const ButtonStdStyled = styled(Button)`
  width: 100%;
  border: 1px solid ${colors.primary};
  margin-bottom: 30px;
  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;

const ButtonPlusStyled = styled(Button)`
  width: 100%;
  border: 1px solid ${colors.primary};
  background-color: ${colors.primary};
  color: white;
  margin-bottom: 30px;
  &:hover {
    background-color: ${colors.success};
    color: white;
    border: 1px solid ${colors.success};
  }
`;

const Home = ({ ...props }) => {
  const { logged_in } = useContext(UserContext);
  const { setType } = useContext(EmailContext);

  const _fetch = async () => {};

  useEffect(() => {
    _fetch();
  }, []);

  return (
    <div>
      <Hero history={props.history} />
      <div className="container-shaped">
        <Container>
          <Section size={"sm"}>
            <h3>Choisissez votre option d'envoi :</h3>
            <small>
              <i>(Cliquez ci-dessous pour) :</i>
            </small>
            <Row sm gutter={40}>
              <BaseBlock>
                <ButtonPlusStyled
                  to={logged_in ? "/envoi-destinataire" : "/envoi-login"}
                  tag={Link}
                  type={""}
                  color={"primary"}
                  onClick={() => setType("plus")}
                  className={"btn"}
                  id={"btn-plus-styled"}
                >
                  Envoyer un Mail Certificate Plus
                </ButtonPlusStyled>

                <UncontrolledTooltip delay={0} placement="top" target="btn-plus-styled" trigger="hover focus">
                  Choisir l'option Plus ?
                </UncontrolledTooltip>

                <Row center className={"mb-2 d-flex align-items-start"}>
                  <div style={{ flex: 0 }}>
                    <div className="icon icon-shape icon-shape-success rounded-circle">
                      <i className="ni ni-check-bold" />
                    </div>
                  </div>
                  <div>
                    <div className={"display-3"}>
                      Envoi <strong style={{ color: colors.success }}>Plus</strong> {PLUS_PRICE}€
                    </div>
                    <p>
                      - Le destinaire reçoit un email avec un lien pour télécharger le courrier.
                      <br />
                      - L'expéditeur a l'option de préserver son anonymat avant que le destinataire n'accepte le courrier, ou peut
                      choisir de rendre son identité visible.
                      <br />
                      - Le destinataire est informé de l'envoi du courrier par email et par SMS.
                      <br />- Un code de vérification est envoyésur le protable du destinataire.
                    </p>
                  </div>
                </Row>
              </BaseBlock>
              <BaseBlock>
                <ButtonStdStyled
                  to={logged_in ? "/envoi-destinataire" : "/envoi-login"}
                  tag={Link}
                  type={""}
                  color={"neutral"}
                  onClick={() => setType("standard")}
                  className={"btn"}
                  id={"btn-std-styled"}
                >
                  Envoyer un Mail Certificate Standard
                </ButtonStdStyled>

                <UncontrolledTooltip delay={0} placement="top" target="btn-std-styled" trigger="hover focus">
                  Choisir l'option Standard ?
                </UncontrolledTooltip>

                <Row center className={"mb-2 d-flex align-items-start"}>
                  <div style={{ flex: 0 }}>
                    <div className="icon icon-shape icon-shape-success rounded-circle">
                      <i className="ni ni-check-bold" />
                    </div>
                  </div>
                  <div>
                    <div className={"display-3"}>
                      Envoi <strong style={{ color: colors.primary }}>Standard</strong> {STANDARD_PRICE}€
                    </div>
                    <p>
                      - Le destinataire reçoit instantanément un email avec un lien pour télécharger le courrier.
                      <br />- Le destinataire est informé de l'identité de l'expéditeur avant acceptation de son courrier avec
                      horodatage certifié eIDAS.
                    </p>
                  </div>
                </Row>
              </BaseBlock>
            </Row>
          </Section>

          <Section>
            <Row reverse sm gutter={40}>
              <div>
                <div style={{ maxWidth: 500 }}>
                  <h2>
                    Simplifiez-vous la vie !
                    <br />
                  </h2>
                  <div className="display-3">Dématerialisez</div>
                  <p>
                    Fini la queue à La Poste pour une lettre recommandée ! Plus d'impression papier !
                    <br />
                    Notre service disponible 24/7 vous permet d'envoyer instantanément un courrier certifié par horodatage par
                    voie électronique avec accusé de réception.
                    <br />
                  </p>
                </div>
              </div>
              <div>
                <Row className={"mb-2"}>
                  <div style={{ flex: 0 }}>
                    <div className="icon icon-shape icon-shape-success rounded-circle">
                      <i className="ni ni-check-bold" />
                    </div>
                  </div>
                  <div>
                    <div className={"display-3"}>Envoi du courrier en ligne</div>
                    <small style={{ textAlign: "justify" }}>Avec accusé de réception</small>
                  </div>
                </Row>
                <Row className={"mb-2"}>
                  <div style={{ flex: 0 }}>
                    <div className="icon icon-shape icon-shape-success rounded-circle">
                      <i className="ni ni-check-bold" />
                    </div>
                  </div>
                  <div>
                    <div className={"display-3"}>Gain de temps et de simplicité</div>
                    <small style={{ textAlign: "justify" }}>Vos envois de courrier en ligne en quelques clics </small>
                  </div>
                </Row>
                <Row className={"mb-2"}>
                  <div style={{ flex: 0 }}>
                    <div className="icon icon-shape icon-shape-success rounded-circle">
                      <i className="ni ni-check-bold" />
                    </div>
                  </div>
                  <div>
                    <div className={"display-3"}>Sécurisé et avec horodatage certifié eIDAS</div>
                    <small style={{ textAlign: "justify" }}>Identification par Mail ou SMS</small>
                  </div>
                </Row>
              </div>
            </Row>
          </Section>
          <Section size={"sm"}>
            <BaseBlock b={5} t={0}>
              <h2>
                Envoi <b style={{ color: colors.primary }}>Standard</b> ou <b style={{ color: colors.success }}>Plus</b>, comment
                ça marche ?
              </h2>
            </BaseBlock>
            <Row sm gutter={20}>
              <HowToItem>
                <div>
                  <div className="icon icon-shape icon-shape-primary rounded-circle">
                    <b>1</b>
                  </div>
                </div>
                <div className="display-2">
                  <h3>Envoyez votre courrier certifié par horodatage</h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Rédigez votre courrier en quelques minutes sur notre site, ajoutez des <b>pièces jointes</b> et envoyez-le à un
                  ou <b>plusieurs destinataires</b>.
                </p>
                <div>
                  <ShadowedImg alt={"Mail-certificate comment ça marche 2"} src={require("../assets/img/brand/ccm6.png")} />
                  <ShadowedImg alt={"Mail-certificate comment ça marche 3"} src={require("../assets/img/brand/ccm7.png")} />
                </div>
              </HowToItem>
              <HowToItem>
                <div>
                  <div className="icon icon-shape icon-shape-danger rounded-circle">
                    <b>2</b>
                  </div>
                </div>
                <div className="display-2">
                  <h3>Notification des destinataires</h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Les destinataires reçoivent une notification pour <b style={{ color: colors.success }}>accepter</b> ou{" "}
                  <b style={{ color: colors.danger }}>refuser</b> le courrier. Le courrier avec horodatage certifié eIDAS expire
                  au bout de 15 jours si aucune action n'a eu lieu.
                </p>
                <div style={{ textAlign: "left" }}>
                  <p>
                    - Envoi <strong>Standard</strong> : identité de l'expéditeur visible et notification par email.
                  </p>
                  <p>
                    - Envoi <strong>Plus</strong> : vous avez le choix :
                    <ul>
                      <li>de cacher votre identité avant l’acceptation de votre destinataire.</li>
                      <li>de laisser votre identité visible à votre destinataire.</li>
                    </ul>
                  </p>
                </div>
                <div>
                  <ShadowedImg alt={"Mail-certificate comment ça marche 4"} src={require("../assets/img/brand/ccm2.png")} />
                  <ShadowedImg alt={"Mail-certificate comment ça marche 5"} src={require("../assets/img/brand/ccm5.png")} />
                </div>
              </HowToItem>
              <HowToItem>
                <div>
                  <div className="icon icon-shape icon-shape-success rounded-circle">
                    <b>3</b>
                  </div>
                </div>
                <div className="display-2">
                  <h3>Recevez une preuve de dépôt</h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Vous recevez par email une preuve de dépôt à l'envoi et un accusé de réception lorsque le destinataire accepte
                  le courrier.
                </p>
                <p style={{ textAlign: "left" }}>
                  - Envoi <strong>Plus</strong> : un code de vérification est envoyé sur le portable de votre destinataire.
                </p>
                <div>
                  <ShadowedImg alt={"Mail-certificate comment ça marche 7"} src={require("../assets/img/brand/ccm4.png")} />
                </div>
              </HowToItem>
              <HowToItem>
                <div>
                  <div className="icon icon-shape icon-shape-default rounded-circle">
                    <b>4</b>
                  </div>
                </div>
                <div className="display-2">
                  <h3>Confirmation et Accusé de Réception</h3>
                </div>
                <p style={{ textAlign: "justify" }}>Votre ou vos destinataire ont accepté votre mail certifié.</p>
                <p style={{ textAlign: "left" }}>Vous recevez par mail la preuve d’accusé réception de votre courrier. </p>
                <div>
                  <ShadowedImg alt={"Mail-certificate comment ça marche 6"} src={require("../assets/img/brand/ccm3.png")} />
                </div>
              </HowToItem>
            </Row>
          </Section>
          <Section>
            <Row reverse sm>
              <Card>
                <CardHeader>
                  <h3>Pour particuliers et professionnels</h3>
                </CardHeader>
                <CardBody>
                  <p>
                    <b>
                      mail-certficate.com permet d'envoyer des courriers avec horodatage certifié eIDAS en ligne avec accusé de
                      reception
                    </b>
                  </p>
                  <p>
                    L'accusé de réception est envoyé immédiatement. Tout ce service vous est proposé au meilleur prix du marché.
                    Le site mail-certificate.com vous permet d'envoyer vos courriers avec horodatage certifié eIDAS en ligne en
                    quelques clics chez vous, quand vous voulez.
                  </p>
                  <p>
                    Le courrier certifié électronique du site mail-certificate est un mail avec horodatage certifié eIDAS avec
                    code de vérification envoyé par SMS sur le portable du destinataire.
                  </p>
                </CardBody>
              </Card>
            </Row>
            <Row sm className={"mt-4"}>
              <Card>
                <CardHeader>
                  <h3>Nouveaux articles</h3>
                </CardHeader>
                <CardBody>
                  <b>
                    L'envoi de courrier avec horodatage certifié eIDAS en ligne vous fait bénéficier de nombreux avantages.
                    Découvrez-les au travers de nos articles.
                  </b>
                  <div>
                    <Link to={"/blog/resilier-forfait-telephone-mobile-courrier-electronique-certifie"}>
                      Comment résilier facilement votre forfait de téléphone mobile avec le mail recommandé certifié ?
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/simplifier-resiliation-abonnement-internet-courrier-electronique-certifie"}>
                      Simplifier la résiliation de votre abonnement Internet grâce au mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-en-ligne"}>
                      Le courrier avec horodatage certifié eIDAS en ligne : Comment ça marche ?
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/resilier-abonnement-salle-sport-courrier-electronique-certifie"}>
                      Résilier son abonnement à la salle de sport en toute sérénité avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie"}>Le courrier avec horodatage certifié eIDAS en ligne</Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie-internet"}>
                      Envoyer un courrier avec horodatage certifié eIDAS sur internet
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/resilier-contrats-securite-courrier-electronique-certifie"}>
                      Résilier vos contrats en toute sécurité avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/gerer-litiges-commerciaux-courrier-electronique-certifie"}>
                      Gérer efficacement les litiges commerciaux avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/fiabilite-notifications-legales-courrier-electronique-certifie"}>
                      Assurer la fiabilité des notifications légales avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/utiliser-courrier-electronique-certifie-resoudre-litiges"}>
                      Utiliser le mail recommandé certifié pour résoudre les litiges efficacement
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/fiabilite-communications-institutionnelles-courrier-electronique-certifie"}>
                      Renforcer la fiabilité des communications institutionnelles avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/proteger-propriete-intellectuelle-courrier-electronique-certifie"}>
                      Protéger votre propriété intellectuelle avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/simplifier-transactions-immobilieres-courrier-electronique-certifie"}>
                      Simplifier les transactions immobilières avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/faciliter-gestion-heritage-courrier-electronique-certifie"}>
                      Faciliter la gestion de l'héritage avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/optimiser-confirmation-rendez-vous-courrier-electronique-certifie"}>
                      Optimiser la confirmation de rendez-vous importants avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/securiser-envoi-documents-confidentiels-courrier-electronique-certifie"}>
                      Sécuriser l'envoi de documents confidentiels avec le mail recommandé certifié
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/maximiser-chances-candidatures-courrier-electronique-certifie"}>
                      Maximiser vos chances dans les candidatures avec le mail recommandé certifié
                    </Link>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3>Tous les articles</h3>
                </CardHeader>
                <CardBody>
                  <b>
                    Accédez <Link to={"/blog"}>ici</Link> à tous les articles
                  </b>
                  <div>
                    <Link to={"/blog/lettre-recommandee-vs-courrier-certifie"}>
                      Lettre recommandée vs courrier avec horodatage certifié eIDAS en ligne
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/convocation-assemblee-generale"}>
                      Convoquer une assemblée générale annuelle par courrier avec horodatage certifié eIDAS
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/attention-ou-intention"}>Attention ou intention ?</Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie-en-ligne"}>
                      Les caractéristiques du courrier avec horodatage certifié eIDAS en ligne
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie-vs-lettre-recommandee-duel-generation"}>
                      Courrier certifié vs lettre recommandée : duel des générations
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-prioritaire"}>Le courrier prioritaire sur Internet : rapide et efficace.</Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie-rapide"}>
                      Envoyer un courrier avec horodatage certifié eIDAS en ligne rapidement, c’est possible ?
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/courrier-certifie-urgence"}>
                      Courrier en urgence : le courrier avec horodatage certifié eIDAS en ligne !
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/lre-vs-lettre-recommandee-papier-differences-avantages"}>
                      LRE vs Lettre Recommandée Papier : Quelles Différences et Avantages ?
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/lettre-recommandee-electronique-legislation-aspects-juridiques"}>
                      Lettre Recommandée Électronique (LRE) : Législation et Aspects Juridiques
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/mail-recommande-renforcer-securite-echanges-professionnels"}>
                      Comment un Mail Recommandé Peut Renforcer la Sécurité de Vos Échanges Professionnels
                    </Link>
                  </div>
                  <div>
                    <Link to={"/blog/lettre-recommandee-electronique-vs-papier-cout-rapidite-securite"}>
                      Lettre Recommandée ou Lettre Recommandée Électronique : Coût, Rapidité, et Sécurité
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Section>
        </Container>
      </div>
    </div>
  );
};

export default Home;
