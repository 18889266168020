import React, { useState } from "react";
import styled from "styled-components";
import api from "../../utils/api";
import { Form } from "reactstrap";
import Button from "../Button";
import InputGroup from "../InputGroup";

const LoginFormWrapper = styled.div``;

const LoginForm = ({ onSuccess, ...props }) => {
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const _submit = async (e) => {
        e.preventDefault();

        try {
            const rep = await api.post("/login", { email, password });

            if (rep && rep.id) {
                onSuccess(rep);
            } else {
                setError(rep.error_message);
            }
        } catch (error) {
            console.error("Erreur lors de la connexion de l'utilisateur", error);
            setError("Une erreur est survenue lors de la connexion. Veuillez réessayer.");
        }
    };

    return (
        <LoginFormWrapper>
            <Form onSubmit={_submit}>
                <InputGroup value={email} onChangeText={setEmail} type="email" placeholder={"Email"} icon={"fa fa-envelope-o"} name={"email"} />
                <InputGroup onChangeText={setPassword} type="password" placeholder={"Password"} icon={"fa fa-lock"} value={password} error={error} name={"password"} />
                <div className="form-group">
                    <Button right type={"submit"}>
                        Continuer
                    </Button>
                </div>
            </Form>
        </LoginFormWrapper>
    );
};

export default LoginForm;
