import React from "react";
import styled from "styled-components";
import Card from "reactstrap/es/Card";
import { Link } from "react-router-dom";
import CardBody from "reactstrap/es/CardBody";
import { Row } from "../Grid";
import { colors } from "../../utils/constants";

const InternalAdWrapper = styled.div``;

const InternalAd = ({ ...props }) => {
  return (
    <InternalAdWrapper>
      <Link to={"/"} style={{ color: colors.primary }}>
        <Card className={"shadow"} style={{ maxWidth: 600 }}>
          <CardBody>
            <Row>
              <div>
                <p className="lead" style={{ margin: 0 }}>
                  <b>Particuliers et professionnels,</b>
                  <br />
                  <b>Inscrivez-vous</b> sur Certificate et envoyez rapidement vos courriers avec horodatage certifié eIDAS en
                  ligne !
                </p>
              </div>
              <div style={{ flex: 0, textAlign: "center" }}>
                <img
                  alt={"Inscrivez vous sur Mail-Certificate"}
                  src={require("assets/img/ill/C.png")}
                  style={{ minWidth: 100 }}
                />
              </div>
            </Row>
          </CardBody>
        </Card>
      </Link>
    </InternalAdWrapper>
  );
};

export default InternalAd;
