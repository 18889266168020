import React from 'react';
import styled from 'styled-components';

const ErrorTextWrapper = styled.span`
  
`;

const ErrorText = ({children, ...props}) => {
    return <ErrorTextWrapper className={'text-warning'} {...props}>
        {children}
    </ErrorTextWrapper>;
};

export default ErrorText;