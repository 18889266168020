import React, { useContext, useState } from "react";
import * as Draft from "draft-js";
import Container from "reactstrap/es/Container";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../../components/Button";
import { EmailContext } from "../../contexts/email";
import { UserContext } from "../../contexts/user";
import InfoText from "../../components/InfoText";
import TemplatesModal from "../../modals/TemplatesModal";
import MailHeader from "../../components/blocks/MailHeader";
import Section from "../../components/Section";
import BaseBlock from "../../components/BaseBlock";
import { Row } from "../../components/Grid";
import Dropzone from "../../components/Dropzone";

const Redaction = ({ ...props }) => {
  const { content, setContent, recipients, attachments, setAttachments } = useContext(EmailContext);
  const { logged_in } = useContext(UserContext);
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);

  if (!recipients.length) props.history.push("/envoi-destinataire");

  const _onChange = (e) => {
    setContent(e);
  };

  const _next = () => {
    if (logged_in) props.history.push("/envoi-verification");
    else props.history.push("/envoi-login");
  };

  const handleTab = (event) => {
    event.preventDefault();
    const newState = Draft.Modifier.replaceText(content.getCurrentContent(), content.getSelection(), "        ");
    setContent(Draft.EditorState.push(content, newState, "insert-characters"));
  };

  return (
    <Container>
      <Section top>
        <TemplatesModal open={templatesModalOpen} toggle={() => setTemplatesModalOpen(false)} />
        <BaseBlock>
          <Row center>
            <div>
              <h1>Envoyer un email avec horodatage certifié eIDAS</h1>
            </div>
            <div className={"hidden-md"} style={{ textAlign: "right" }}>
              <img style={{ maxHeight: 200 }} src={require("assets/img/ill/4.png")} alt="" />
            </div>
          </Row>
        </BaseBlock>
        <Button style={{ minWidth: "50%", marginBottom: 50 }} onClick={() => setTemplatesModalOpen(true)} center={true}>
          Choisir un modèle ?
          <br />
          <InfoText>Résiliation, changement d'adresse, ...</InfoText>
        </Button>
        <div className="shadow">
          <Editor
            editorState={content}
            editorClassName="editorClassName"
            toolbarClassName="toolbarClass"
            onEditorStateChange={_onChange}
            onTab={handleTab}
            toolbar={{
              options: ["inline", "fontSize", "list", "textAlign", "colorPicker", "link", "emoji", "history"],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough", "monospace"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            toolbarCustomButtons={[
              <div className="editor-letter-header">
                <br />
                <MailHeader />
                <hr />
              </div>,
            ]}
          />
        </div>
        <BaseBlock>
          <div className={"lead"}>
            Vous pouvez également ajouter ci-dessous plusieurs fichiers joints <b>PDF</b>, <b>DOC</b> ou <b>images</b>.
          </div>
        </BaseBlock>
        <BaseBlock>
          <Dropzone step="REDACTION" files={attachments} setFiles={setAttachments} />
        </BaseBlock>
        <Button onClick={_next} right style={{ marginTop: 10 }}>
          Continuer
        </Button>
      </Section>
    </Container>
  );
};

export default Redaction;
