import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "reactstrap";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import SignupForm from "../components/blocks/SignupForm";
import api from "../utils/api";
import { UserContext } from "../contexts/user";

const AddContactModalWrapper = styled.div``;

const AddContactModal = ({ open, toggle, onSuccess, ...props }) => {
  const [errors, setErrors] = useState({});
  const { user } = useContext(UserContext);

  const _submit = async (data) => {
    let rep = await api.post("/contacts", {
      ...data,
      user_id: user.id,
    });

    if (rep.id) {
      onSuccess();
    } else if (rep.errors) {
      setErrors(rep.errors);
    } else {
      alert(rep.error_message);
    }
  };
  return (
    <AddContactModalWrapper>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader>Ajouter un contact</ModalHeader>
        <ModalBody>
          <SignupForm
            noSignup={true}
            onSuccess={_submit}
            mobilePlaceholer={'Téléphone mobile destinataire (pour l\'option "Plus SMS")'}
            errors={errors}
          />
        </ModalBody>
      </Modal>
    </AddContactModalWrapper>
  );
};

export default AddContactModal;
