import React, { useEffect, useState } from "react";
import Container from "reactstrap/es/Container";
import Alert from "reactstrap/es/Alert";
import Section from "../components/Section";
import api, { API_BASE_URL } from "../utils/api";
import InternalAd from "../components/blocks/InternalAd";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import BaseBlock from "../components/BaseBlock";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AcceptedBackward = ({ ...props }) => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  let query = useQuery();
  const [recipient, setRecipient] = useState({});

  useEffect(() => {
    _doAccept();
  }, []);

  const _doAccept = async () => {
    let rep = await api.post(`/emails/${query.get("tk")}/recipients/${query.get("rtk")}/accept`, {
      email_token: query.get("tk"),
    });

    setLoaded(true);

    if (rep.error_message) setError(rep.error_message);
    else {
      if (typeof window.gtag !== "undefined")
        window.gtag("event", "accepted", {
          event_category: "email",
          event_label: props.match.params.recipient_id,
          value: 1,
        });
      setRecipient(rep);
    }
  };

  return (
    <Container>
      <Section top>
        <Helmet>
          <title>Acceptation de votre email avec horodatage certifié eIDAS</title>
          <meta name={"description"} content={"Acceptez vos courriers électroniques avec horodatage certifié eIDAS."} />
        </Helmet>
        <h1>Acceptation de votre email avec horodatage certifié eIDAS</h1>
        {loaded && (
          <div>
            {error ? (
              <div>
                <Alert className={"alert-danger"}>{error}</Alert>
              </div>
            ) : (
              <div>
                <p>Merci, vous venez d'accepter un courrier certifié par horodatage.</p>
                <Alert>
                  Vous avez accepté un mail recommandé certifié par horodatage, vous le recevrez par email dans quelques instants.
                </Alert>
                <div className="lead">Vous pouvez également télécharger les fichiers ci-dessous :</div>
                <BaseBlock>
                  <a onClick={() => (window.location = `${API_BASE_URL}/recipients/${recipient.id}/${query.get("rtk")}/pdf`)}>
                    Télécharger le courrier
                  </a>
                  {typeof recipient.attachments !== "undefined" &&
                    recipient.attachments.map((attachment) => {
                      return (
                        <div>
                          <a
                            onClick={() =>
                              (window.location = `${API_BASE_URL}/recipients/${recipient.id}/${query.get("rtk")}/attachments/${
                                attachment.id
                              }`)
                            }
                          >
                            {attachment.name}
                          </a>
                        </div>
                      );
                    })}
                </BaseBlock>
              </div>
            )}
          </div>
        )}
        <Section>
          <InternalAd />
        </Section>
      </Section>
    </Container>
  );
};

export default AcceptedBackward;
