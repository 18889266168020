import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";
import InternalAd from "../../components/blocks/InternalAd";

const Legislation = ({ ...props }) => {
    return (
        <Container>
        </Container>
    );
};

export default Legislation;
