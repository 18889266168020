import React from "react";
import Container from "reactstrap/es/Container";
import { Row } from "./Grid";

const Hero = ({ ...props }) => {
  return (
    <div>
      <section className={"section section-hero"}>
        <Container>
          <Row sm center>
            <div>
              <div className="display-1">
                <h1>courrier électronique certifié par horodatage eIDAS avec accusé de réception</h1>
                <div className={"subtitle"}>
                  Horodatage par <strong>Certigna qualifié eIDAS</strong> de tous les envois avec{" "}
                  <strong>numéro de certificat</strong> en découlant
                </div>
              </div>
              <div>
                <p>
                  Mail Certificate vous propose un service d'email certifié par horodatage qualifié eIDAS :{" "}
                  <strong>fiable</strong>, <strong>rapide</strong> et <strong>sécurisé</strong> avec{" "}
                  <strong>accusé d'envoi</strong> et <strong>de réception</strong>.
                  <br />
                  Vous pouvez si vous le souhaitez <strong>cacher votre identité</strong> à votre destinataire et ainsi garantir
                  la <strong>confidentialité de votre courrier</strong> jusqu'au moment de son ouverture.
                  <br />
                  Vos courriers sont <strong>horodatés et certifiés eIDAS</strong>, dans l'option "Plus" un code de vérification
                  est envoyé par <strong>SMS</strong> sur le portable du destinataire.
                </p>
              </div>
            </div>
            <div className={"hero-illu"}>
              <img src={require("assets/img/ill/2.png")} alt="" />
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Hero;
