import React, { useState, useEffect } from "react";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import { Row } from "../../components/Grid";
import InputGroup from "../../components/InputGroup";
import BaseBlock from "../../components/BaseBlock";
import Button from "../../components/Button";
import CheckAuth from "../../components/blocks/CheckAuth";
import api, {API_BASE_URL} from "../../utils/api";
import { Helmet } from "react-helmet/es/Helmet";
import styled from "styled-components";
import { colors } from "../../utils/constants";
import { readableDate } from "../../utils/utils";

const UserRow = styled.tr`
    background-color: ${(props) => (props.selected ? colors.success : "white")};
`;

const EmailButton = styled(Button)`
    margin-bottom: 5px;
`;

const AdminPage = ({ ...props }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userEmails, setUserEmails] = useState([]);
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [stats, setStats] = useState(false);

    const selectedUserType = selectedUser && selectedUser.user_id ? "contact" : "user";

    useEffect(() => {
        if (selectedUser) {
            (async () => {
                const emails = await api.get(`/emails?${selectedUserType}_id=${selectedUser.id}`);
                if (!emails.error_message) {
                    setUserEmails(emails);
                } else {
                    setUserEmails([]);
                }
            })();
        } else {
            setUserEmails([]);
        }
    }, [selectedUser]);

    const deleteUser = async () => {
        if (selectedUser) {
            const confirmed = window.confirm(`Voulez-vous définitivement supprimer l'utilisateur ${selectedUser.email}?`);
            if (confirmed) {
                await api.delete(`/users/${selectedUser.id}`);
                setUsers(users.filter((user) => user.id !== selectedUser.id));
            }
        }
    };

    const searchByEmail = async () => {
        const users = await api.get("/users_contacts", { email: search });
        if (!users.error_message) {
            setUsers(users);
        }
    };

    const getStats = async () => {
        const stats = await api.get("/stats", { startDate });

        if (!stats.error_message) {
            setStats(stats);
        }
    };

    const getCSV = async () => {
        window.location =API_BASE_URL+ "/stats/csv";
    };

    const sendEmail = async (id, type) => {
        const confirmed = window.confirm(`Voulez-vous renvoyer l'email de l'utilisateur ${selectedUser.email} ?`);
        if (confirmed) await api.post(`/emails/${id}/sends`, { type });
    };

    const formatDateFrench = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const addCredit = () => {
        const credit = window.prompt(`Nouvelle valeur pour les crédits de ${selectedUser.email}?`);
        if (credit) {
            api.put(`/users/${selectedUser.id}`, { credit }).then(user => {
                setSelectedUser({
                    ...selectedUser,
                    credit: user.credit,
                });
            });
        }
    };

    return (
        <CheckAuth level={2}>
            <Helmet>
                <title>Administration</title>
                <meta name={"description"} content={"Page d'administration pour la gestion des utilisateurs"} />
            </Helmet>
            <Container>
                <Section top>
                    <h1>Administration</h1>
                    <BaseBlock>
                        <h3>Nombre d'inscriptions</h3>
                        <Row>
                            <div>
                                <InputGroup placeholder="Sélectionnez une date" type="date" icon="fa fa-search" value={startDate} onChangeText={setStartDate} />
                            </div>
                            <Button onClick={getStats} type="button" disabled={!startDate} color="success" containerStyle={{ flex: 0 }}>
                                Filtrer
                            </Button>
                        </Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Nombre d'utilisateurs</th>
                                    <th>Période</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Utilisateurs inscrits au total</td>
                                    <td>{stats.usersTotal}</td>
                                    <td>-</td>
                                    <td>
                                        <Button color="primary" onClick={getCSV}>
                                            Télécharger le fichier .CSV
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Utilisateurs inscrits le mois en cours</td>
                                    <td>{stats.usersMonth}</td>
                                    <td>Mois en cours</td>
                                    <td>
                                    </td>
                                </tr>
                                {startDate && (
                                    <tr>
                                        <td>Utilisateurs inscrits sur la date sélectionnée</td>
                                        <td>{stats.usersDate}</td>
                                        <td>{formatDateFrench(startDate)}</td>
                                        <td>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </BaseBlock>
                    <BaseBlock>
                        <h3>Recherche utilisateur</h3>
                        <Row>
                            <InputGroup placeholder={"Rechercher par e-mail"} icon={"fa fa-search"} value={search} onChangeText={setSearch} />
                            <Button onClick={searchByEmail} type="button" disabled={!search} color="success" containerStyle={{ flex: 0 }}>
                                Rechercher
                            </Button>
                        </Row>
                        <BaseBlock>
                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>type</th>
                                        <th>prenom</th>
                                        <th>nom</th>
                                        <th>email</th>
                                        <th>tel</th>
                                        <th>Sélectionner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <UserRow selected={user.id === selectedUser && selectedUser.id}>
                                            <td>{user.id}</td>
                                            <td>{user.user_id ? `Destinataire` : `Expediteur`}</td>
                                            <td>{user.prenom}</td>
                                            <td>{user.nom}</td>
                                            <td>{user.email}</td>
                                            <td>{user.tel}</td>
                                            <td>
                                                <Button disabled={user.id === selectedUser && selectedUser.id} onClick={() => setSelectedUser(user)}>
                                                    Sélectionner
                                                </Button>
                                            </td>
                                        </UserRow>
                                    ))}
                                </tbody>
                            </table>
                        </BaseBlock>
                        {selectedUser && (
                            <div>
                                <h3>Utilisateur sélectionné</h3>
                                <Row>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Prénom</th>
                                                <th>Nom</th>
                                                <th>Email</th>
                                                <th>Tel</th>
                                                <th>Crédit disponible</th>
                                                <th>Modifier les crédits</th>
                                                <th>Supprimer l'utilisateur</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.id}</td>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.prenom}</td>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.nom}</td>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.email}</td>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.tel}</td>
                                                <td style={{ color: colors.primary, fontWeight: "bold" }}>{selectedUser.credit}</td>
                                                <td>
                                                    <Button onClick={addCredit} type="button" color="success">
                                                        Modifier les crédits
                                                    </Button>
                                                </td>
                                                <td>
                                                    {selectedUserType === "user" && (
                                                        <Button onClick={deleteUser} type="button" color="danger">
                                                            Supprimer l'utilisateur
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Row>
                                <div>
                                    <BaseBlock t={0}>
                                        <h3>Courrier de l'utilisateur sélectionné</h3>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Réference</th>
                                                    <th>Date</th>
                                                    <th>Destinataires</th>
                                                    <th>Renvoyer un email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userEmails.map((email) => (
                                                    <tr>
                                                        <td>{email.id}</td>
                                                        <td>{email.reference}</td>
                                                        <td>{readableDate(email.createdAt)}</td>
                                                        <td>
                                                            {email.recipients.map((recipient) => (
                                                                <div>
                                                                    {recipient.contact.email} : {recipient.status}
                                                                </div>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <EmailButton onClick={() => sendEmail(email.id, "initialsender")}>Email initial expediteur</EmailButton>
                                                            <EmailButton onClick={() => sendEmail(email.id, "initial")}>Email initial destinataire</EmailButton>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </BaseBlock>
                                </div>
                            </div>
                        )}
                    </BaseBlock>
                </Section>
            </Container>
        </CheckAuth>
    );
};

export default AdminPage;
