import React from 'react';
import {Route} from "react-router-dom";
import MailHistory from "../scenes/account/MailHistory";
import Account from "../scenes/account/Account";
import Contacts from "../scenes/account/Contacts";
import AdminPage from '../scenes/account/AdminPage';

const AccountRoutes = [
        <Route
            path="/compte/historique-emails"
            exact
            render={props => <MailHistory {...props} />}
            key={0}
        />,
        <Route
            path="/compte/contacts"
            exact
            render={props => <Contacts {...props} />}
            key={1}
        />,
        <Route
            path="/compte"
            exact
            render={props => <Account {...props} />}
            key={2}
        />,
         <Route
            path="/admin"
            exact
            render={props => <AdminPage {...props} />}
            key={3}
        />
    ];

export default AccountRoutes;