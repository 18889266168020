import React from "react";
import Container from "reactstrap/es/Container";
import Section from "../../../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import InternalAd from "../../../components/blocks/InternalAd";

const D = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Accusé de réception d'un courrier électronique avec horodatage certifié eIDAS</title>
        <meta
          name={"description"}
          content={
            "Recevez automatiquement de la preuve de dépôt de votre mail recommandé avec horodatage certifié eIDAS en ligne"
          }
        />
      </Helmet>
      <Section top>
        <h2>Accusé de réception d'un courrier électronique avec horodatage certifié eIDAS</h2>
        <p></p>
        <p>
          Le site certificate-mail.com vous informe automatiquement de la preuve de dépôt de votre courrier électronique certifié
          par horodatage en ligne. En option, soyez informé de la bonne réception de votre courrier avec horodatage certifié
          eIDAS, par un accusé de réception. Celui-ci gardera les informations de votre envoi certifié par horodatage : le numéro
          d'identification certifié par horodatage de l'envoi, et la date et l’heure de cet envoi.
        </p>
        <p></p>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default D;
