import React from 'react';
import styled from 'styled-components';
import {colors} from "../utils/constants";

const BBox = styled.div`
  border-radius:50%;
  background-color: ${props=>props.value?colors.success:'white'};
      vertical-align: middle;
  width:20px;
  height: 20px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${props=>!props.value?`border: 1px solid ${colors.primary};`:``}
`;
const BInside = styled.div`
  border-radius:50%;
  background-color: white;
  width:6px;
  height: 6px;
  display: inline-block;
`;
const CBWrapper = styled.div`
  display: inline;
    cursor: pointer;
`;

const Checkbox = ({value, children, onChange=()=>null, ...props}) =>
    <CBWrapper onClick={onChange?onChange:null}>
        <BBox value={value}>
            <BInside />
        </BBox>
        <CBWrapper>
            {children}
        </CBWrapper>
    </CBWrapper>
;

export default Checkbox;