import React, { useContext, useState } from "react";
import styled from "styled-components";
import { EmailContext } from "../../contexts/email";
import Container from "reactstrap/es/Container";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Button from "../../components/Button";
import BaseBlock from "../../components/BaseBlock";
import api from "../../utils/api";
import { UserContext } from "../../contexts/user";
import { Alert, FormGroup, Input, Label } from "reactstrap";
import Dropzone from "../../components/Dropzone";
import CheckAuth from "../../components/blocks/CheckAuth";
import ErrorText from "../../components/ErrorText";
import Progress from "reactstrap/es/Progress";
import { EditorState } from "draft-js";
import Checkbox from "../../components/Checkbox";
import { useLocation } from "react-router-dom";
import { STANDARD_PRICE, PLUS_PRICE } from "../../contexts/email";
import { Row } from "../../components/Grid";

const VerificationWrapper = styled(Container)``;

const Verification = ({ ...props }) => {
  const {
    recipients,
    attachments,
    setAttachments,
    setRecipients,
    contentHtml,
    setContent,
    getContentWithoutRecipient,
    getTotalPrice,
  } = useContext(EmailContext);
  const { user } = useContext(UserContext);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saveCB, setSaveCB] = useState(true);
  const [protecAgainstDestinataireFlash, setprotecAgainstDestinataireFlash] = useState(false);
  const { type, setType } = useContext(EmailContext);
  const [hideSenderName, setHideSenderName] = useState(true);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCanceled = searchParams.get("canceled");
  const isSuccess = searchParams.get("success");

  const goConfirmation = () => {
    if (typeof window.gtag !== "undefined")
      window.gtag("event", "sent", {
        event_category: "email",
        value: 1,
      });
    if (typeof window.fbq !== "undefined") window.fbq("track", "Purchase");

    setRecipients([]);
    setAttachments([]);
    setContent(EditorState.createEmpty());
    setTimeout(() => {
      props.history.push("/envoi-confirmation");
    }, 500);
  };
  if (isSuccess) {
    goConfirmation();
  }

  if (!protecAgainstDestinataireFlash && !recipients.length) props.history.push("/envoi-destinataire");

  const _send = async () => {
    if (!hasAcceptedTerms) {
      setError("Vous devez accepter les termes et conditions avant de pouvoir envoyer l'email.");
      return;
    }
    setLoading(true);
    setProgress(1);
    setprotecAgainstDestinataireFlash(true);

    let email = await api.post("/emails", {
      content: getContentWithoutRecipient(),
      sender_id: user.id,
      recipients: recipients,
      hide_sender_name: hideSenderName,
    });

    if (email.error_message) {
      setError(email.error_message);
      setLoading(false);
    } else if (email.id) {
      let repFiles = await api.postFiles(`/emails/${email.id}/attachments`, attachments, (p) => setProgress(parseInt(p * 0.9)));
      if (repFiles.error_message) {
        setError(repFiles.error_message);
        setLoading(false);
        return;
      }
      setProgress(99);

      let rep = await api.put(`/emails/${email.id}`, { type });

      if (rep.error_message) setError("Erreur lors de l'enregistrement du courrier : " + rep.error_message);
      else {
        let paiementSession = await api.post(`/emails/${email.id}/paiements`, { save_cb: saveCB });
        if (!paiementSession.url) {
          setError("Erreur lors de l'enregistrement du courrier");
        } else {
          window.location = paiementSession.url;
        }
      }
    } else {
      setLoading(false);
      setProgress(0);

      setError("Erreur lors de l'enregistrement");
      setLoading(false);
    }
  };

  const toPay = Math.max(getTotalPrice() - user.credit, 0);

  const OptionWrapper = styled.div`
    margin-left: 30px;
  `;

  return (
    <CheckAuth path={"/envoi-login"}>
      <VerificationWrapper>
        <h1>Votre courrier</h1>
        <Card>
          <CardBody>
            <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
          </CardBody>
        </Card>
        <BaseBlock>
          {attachments.length > 0 && (
            <h2>
              Pièce{attachments.length > 1 ? "s" : ""} Jointe{attachments.length > 1 ? "s" : ""}
            </h2>
          )}{" "}
          <Dropzone step="VERIFICATION" files={attachments} setFiles={setAttachments} />
        </BaseBlock>
        <h2>Destinataires</h2>
        {!recipients.length ? (
          `Vous n'avez pas sélectionné de destinataire`
        ) : (
          <div>
            {recipients.map((r) => (
              <Alert className={"alert-inline mr-2"} color={"success"} key={r.id}>
                {r.email}
              </Alert>
            ))}
          </div>
        )}
        <BaseBlock>
          <BaseBlock>
            <div>
              <Checkbox onChange={() => setType("standard")} value={type === "standard"}>
                Standard
              </Checkbox>
              <small> {STANDARD_PRICE}€ par destinataire</small>
            </div>
            <small>Le destinataire connaitra votre identité avant d'accepter le courrier avec horodatage certifié eIDAS.</small>
          </BaseBlock>
          <BaseBlock>
            <div>
              <Checkbox onChange={() => setType("plus")} value={type === "plus"}>
                Plus
              </Checkbox>
              <small> {PLUS_PRICE}€ par destinataire</small>
            </div>
            {type === "plus" && (
              <FormGroup tag="fieldset">
                <OptionWrapper>
                  <p>Voulez vous cacher votre nom au destinataire ?</p>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="hideSenderName"
                        checked={hideSenderName}
                        onChange={() => setHideSenderName(true)}
                      />{" "}
                      <strong>Oui</strong>
                    </Label>{" "}
                    <small>
                      {" "}
                      - Le destinataire ne connaitra pas votre identité avant d'accepter le courrier avec horodatage certifié
                      eIDAS, et devra s'identifier par SMS pour ouvrir le courrier.
                    </small>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="hideSenderName"
                        checked={!hideSenderName}
                        onChange={() => setHideSenderName(false)}
                      />
                      <strong>Non</strong>
                    </Label>
                    <small>
                      {" "}
                      - Le destinataire aura connaissance votre identité et devra s'identifier par SMS avant d'ouvrir le courrier
                      avec horodatage certifié eIDAS.
                    </small>
                  </FormGroup>
                </OptionWrapper>
              </FormGroup>
            )}
            {type !== "plus" && (
              <small>Vous choisissez ou non de communiquer votre identité au destinataire avant acceptation du courrier.</small>
            )}
          </BaseBlock>
          <BaseBlock>
            <p>Crédit disponible: {user.credit ? `${user.credit}€` : "0€"}</p>
            <p>
              <strong>Total : {getTotalPrice()}€</strong>
            </p>
            <p>
              <strong>Reste à payer : {toPay}€</strong>
            </p>
          </BaseBlock>
        </BaseBlock>
        <BaseBlock>
          <Row>
            <div>
              {toPay > 0 && (
                <Checkbox value={saveCB} onChange={() => setSaveCB(!saveCB)}>
                  Enregistrer le moyen de paiement pour les futures utilisations
                </Checkbox>
              )}
            </div>
          </Row>

          {progress > 0 && (
            <div className="progress-wrapper">
              <div className="progress-info">
                <div className="progress-label">
                  <span>
                    {progress === 1
                      ? "Enregistrement de votre courrier..."
                      : progress < 99
                      ? "Enregistrement des pièces jointes..."
                      : "Envoi du courrier..."}
                  </span>
                </div>
                <div className="progress-percentage">
                  <span>{progress}%</span>
                </div>
              </div>
              <Progress max="100" value={progress} color="success" />
            </div>
          )}
        </BaseBlock>
        <BaseBlock>
          <div>
            <Checkbox value={hasAcceptedTerms} onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}>
              Je demande l’exécution immédiate du service
            </Checkbox>
            <small></small>
          </div>
          <small>
            et accepte de renoncer au droit de rétractation une fois le service réalisé. (cas légal art.L.221-28 point 1° du code
            de la consommation){" "}
          </small>
        </BaseBlock>
        <BaseBlock>
          <Button left onClick={_send} disabled={loading || !recipients.length}>
            Valider
          </Button>
        </BaseBlock>
        <BaseBlock b={8}>
          <ErrorText>{error}</ErrorText>
          {isCanceled && <ErrorText>Échec de paiement. Veillez réessayer.</ErrorText>}
        </BaseBlock>
      </VerificationWrapper>
    </CheckAuth>
  );
};

export default Verification;
