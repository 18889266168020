import React from "react";
import { Route } from "react-router-dom";
import MentionsLegales from "../scenes/static/MentionsLegales";
import NosServices from "../scenes/static/NosServices";
import Faq from "../scenes/static/Faq";
import Legislation from "../scenes/static/Legislation";
import Apropos from "../scenes/static/Apropos";
import CGV from "../scenes/static/CGV";
import PolitiqueConfidentialite from "../scenes/static/PolitiqueConfidentialite";
import PolitiqueCookies from "../scenes/static/PolitiqueCookies";

const StaticRoutes = [
  <Route path="/mentions-legales" exact render={(props) => <MentionsLegales {...props} />} key={1} />,
  <Route path="/nos-services" exact render={(props) => <NosServices {...props} />} key={2} />,
  <Route path="/faq" exact render={(props) => <Faq {...props} />} key={3} />,
  <Route path="/legislation" exact render={(props) => <Legislation {...props} />} key={4} />,
  <Route path="/a-propos" exact render={(props) => <Apropos {...props} />} key={5} />,
  <Route path="/conditions-generales-vente" exact render={(props) => <CGV {...props} />} key={6} />,
  <Route path="/politique-confidentialite" exact render={(props) => <PolitiqueConfidentialite {...props} />} key={7} />,
  <Route path="/politique-cookies" exact render={(props) => <PolitiqueCookies {...props} />} key={8} />,
];

export default StaticRoutes;
