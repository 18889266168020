import React from "react";
import useCached from "../utils/useCached";
import Container from "reactstrap/es/Container";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../components/Section";

const Blog = ({ ...props }) => {
  const [posts = [], loaded] = useCached("/blog/posts", {}, "ALL");

  return (
    <Container>
      <Helmet>
        <title>Articles de Mail Certificate envoi de mail recommandé avec horodatage certifié eIDAS</title>
        <meta
          name={"description"}
          content={
            "Démarches, simplicité, les informations autour du mail recommandé avec horodatage certifié eIDAS sont regroupées dans nos articles"
          }
        />
      </Helmet>
      <Section top>
        <h1>Blog</h1>
        {loaded &&
          posts.map((p) => (
            <Link to={`/blog/${p.slug}`}>
              <h2>{p.title}</h2>
              <p style={{ color: "#2e53cf" }}>{p.excerpt}</p>
            </Link>
          ))}
      </Section>
    </Container>
  );
};

export default Blog;
