import React from "react";
import ReactDOM from "react-dom";

import ClientApp from "./ClientApp";
// TODO ssr
//import ServerApp from "./ServerApp";


ReactDOM.render(
    <ClientApp />,
    document.getElementById("root")
);
