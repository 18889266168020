import React from 'react';
import styled from 'styled-components';

const InfoTextWrapper = styled.em`
  font-weight: 300;
  font-size:0.8em;
`;

const InfoText = ({children, ...props}) => {

    return <InfoTextWrapper {...props}>
        {children}
    </InfoTextWrapper>;
};

export default InfoText;