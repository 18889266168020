import React from "react";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import InternalAd from "../../components/blocks/InternalAd";

const MentionsLegales = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Mentions légales</title>
        <meta
          name={"description"}
          content={"Mentions légales de Mail-certificate, courrier électronique certifié par horodatage"}
        />
      </Helmet>
      <Section top>
        <h2>Mentions légales</h2>

        <p></p>
        <p>
          Le site mail-certificate est édité par Société Mail-certificate 37 A Avenue Egle 78600 Maisons-Laffitte. 952 868 230 RCS
          Versailles. Responsble de publication M.Jacques LEVY Création, développement et design web Retour Internet 175 rue de la
          Leysse 73290 La Motte Servolex Hébergement du site Nuxit
        </p>
        <p></p>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default MentionsLegales;
