import React, { useContext, useEffect, useState } from "react";
import { EditorState, ContentState } from "draft-js";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ReactDOMServer from "react-dom/server";
import MailHeader from "../components/blocks/MailHeader";
import { UserContext } from "./user";
const htmlToDraft = typeof window === "object" && require("html-to-draftjs").default;

let localState = {};
if (typeof window !== "undefined") localState = JSON.parse(localStorage.getItem("EmailContext"));

export const EmailContext = React.createContext({});

const stdPrice = 1.5;
const plusPrice = 2.4;
export const STANDARD_PRICE = stdPrice.toFixed(2);
export const PLUS_PRICE = plusPrice.toFixed(2);


export const EmailContextProvider = ({ ...props }) => {
    const [content, setContent] = useState(
        localState && localState.redactedHtml ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(localState.redactedHtml).contentBlocks)) : EditorState.createEmpty()
    );
    const [recipients, setRecipients] = useState(localState && localState.recipients ? localState.recipients : []);
    const [attachments, setAttachments] = useState([]);
    const [type, setType] = useState("standard");
    const { user, logged_in, fetched } = useContext(UserContext);

    const getTotalPrice = () => {
        const price = type === "plus" ? PLUS_PRICE : STANDARD_PRICE;
        return price * recipients.length;
    };

    let headerHtml = ReactDOMServer.renderToString(<MailHeader userInfos={{ user, logged_in, recipients }} />);
    let redactedHtml = draftToHtml(convertToRaw(content.getCurrentContent()));
    let contentHtml = `<div style="font-family: sans-serif;">${headerHtml}<div style="padding:40px;" class="letter-body">${redactedHtml}</div></div>`;

    useEffect(() => {
        let saveObject = {
            recipients,
            redactedHtml,
        };

        localStorage.setItem("EmailContext", JSON.stringify(saveObject));
    }, [content, recipients]);

    useEffect(() => {
        (async () => {
            if (fetched) {
                let newRecipients = recipients.filter((r) => r.user_id === user.id);
                setRecipients(newRecipients);
            }
        })();
    }, [user, logged_in, fetched]);

    const getContentWithoutRecipient = () => {
        return `<div style="font-family: sans-serif;">${ReactDOMServer.renderToString(<MailHeader userInfos={{ user, logged_in }} />)}<div style="padding:40px;">${redactedHtml}</div></div>`;
    };

    return (
        <EmailContext.Provider
            value={{
                attachments,
                setAttachments,
                content,
                recipients,
                setRecipients,
                setContent,
                contentHtml,
                getContentWithoutRecipient,
                type,
                setType,
                getTotalPrice,
            }}
            {...props}
        />
    );
};
