import React, { useContext, useEffect, useState } from "react";
import api from "../utils/api";

export const UserContext = React.createContext({});

export const UserContextProvider = ({ ...props }) => {
    const ct = useContext(UserContext);
    const [user, setUser] = useState(ct);
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        (async () => {
            let user = await api.get(`/login`);
            if (user && user.id) setUser(user);

            setFetched(true);
        })();
    }, []);

    const logout = async () => {
        await api.post(`/logout`);
        setUser({});
    };

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                logout,
                fetched,
                logged_in: !!Object.keys(user).length,
            }}
            {...props}
        ></UserContext.Provider>
    );
};
