import styled from "styled-components";
import {device} from "../utils/constants";


export const Row = styled.div`
    display:flex;
    flex-direction:${props=>props.rowReverse?'row-reverse':'row'};
    align-items: ${props=>props.center?`center`:`stretch`};
     
    > * {
      flex:1;
      :not(:last-child) {
        margin-right:${props=>props.gutter?props.gutter:20}px;
      }
    }
    
    ${props=>!!props.between&&`
    justify-content:space-between;
       > * {
          flex:none;
          align-items: center;
        }
    `}
    
    ${props => !!props.lg && `
        @media ${device.lg}{
            display:block;
            
             > * {
                flex:1;
                :not(:last-child) {
                    margin-right:0;
                    margin-bottom:20px;

                }
            }
        }
    `}        
    ${props => !!props.md && `
        @media ${device.md}{
            flex-direction:${props.reverse?'column-reverse':'column'};
            
             > * {
                flex:1;
                width:100%;
                :not(:last-child) {
                    margin-right:0;
                    margin-bottom:20px;

                }
            }
        }
    `}    
    ${props => !!props.sm && `
        @media ${device.sm}{
            flex-direction:${props.reverse?'column-reverse':'column'};
            
             > * {
                flex:1;
                width:100%;
                
                :not(:last-child) {
                                    margin-right:0;

                }
               
                :not(${props.reverse?':first-child':':last-child'}) {
                    margin-bottom:20px;

                }
            }
        }
    `}    
    ${props => !!props.xs && `
        @media ${device.xs}{
            flex-direction:${props.reverse?'column-reverse':'column'};
            
             > * {
                flex:1;
                width:100%;
                :not(:last-child) {
                    margin-right:0;
                    margin-bottom:20px;

                }
            }
        }
    `}
`;

export const Column = styled.div`
  display:flex;
  flex-direction:column;
  
  > * {
      flex:1;
      
      :not(:last-child) {
        margin-bottom:${props=>props.gutter?props.gutter:10}px;
      }
    }
`;
