import React, { useContext } from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Button from "../../components/Button";
import { Alert } from "reactstrap";
import BaseBlock from "../../components/BaseBlock";
import { EmailContext } from "../../contexts/email";
import CheckAuth from "../../components/blocks/CheckAuth";
import ContactList from "../../components/blocks/ContactList";
import Section from "../../components/Section";

const DestinataireWrapper = styled.div``;

const Destinataire = ({ ...props }) => {
  const { setRecipients, recipients } = useContext(EmailContext);

  const _next = () => {
    props.history.push("/envoyer-mail-recommande");
  };

  let recipientsIds = recipients.map((r) => r.id);

  const _toggle = (r) => {
    if (!!~recipientsIds.indexOf(r.id)) setRecipients(recipients.filter((cr) => cr.id !== r.id));
    else setRecipients([...recipients, r]);
  };

  return (
    <CheckAuth path={"/envoi-login"}>
      <DestinataireWrapper>
        <Container>
          <Section top>
            <h1>Destinataires</h1>
            <BaseBlock>
              {!recipients.length ? (
                `Vous n'avez pas sélectionné de destinataire`
              ) : (
                <div>
                  {recipients.map((r) => (
                    <Alert toggle={() => _toggle(r)} className={"alert-inline mr-2"} key={r.id}>
                      {r.email}
                    </Alert>
                  ))}
                </div>
              )}
            </BaseBlock>
            <BaseBlock>
              <ContactList
                selected={recipientsIds}
                onSelect={_toggle}
                rightElement={
                  <Button disabled={!recipients.length} right onClick={_next}>
                    Continuer
                  </Button>
                }
              />
            </BaseBlock>
          </Section>
        </Container>
      </DestinataireWrapper>
    </CheckAuth>
  );
};

export default Destinataire;
