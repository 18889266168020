import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ScrollToTopOnMount = ({children, ...props}) => {
    const {action, location} = useHistory();
    const prevLocation = usePrevious(location.pathname);

    useEffect(()=>{
        if((!!prevLocation&&location.pathname!==prevLocation) && action==="PUSH"){
            window.scrollTo(0, 0);

            if(typeof window.gtag !=='undefined')// sends a pageview
                setTimeout(()=>{
                    window.gtag('config', 'UA-63159300-1',
                        {page_path:location.pathname}
                    );
                }, 300);
            if(window.Tawk_API&&window.Tawk_API.hasOwnProperty('addEvent'))
                setTimeout(()=>{
                    window.Tawk_API.addEvent('navigation', {url:location.pathname, from:prevLocation}, e=>null);
                }, 300);
        }

    }, [location]);

    return children;
};

export default ScrollToTopOnMount;