import React, {useEffect, useState} from "react";
import api from "./api";
import {useToasts} from "react-toast-notifications";

const useElements = (path, data = {}) => {
    const [elements, setElements] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const {addToast} = useToasts();

    useEffect(()=>{
        _fetch();
    }, []);

    const _fetch = async () => {
        let rep = await api.get(path, data);
        if(!rep.error_message)
            setElements(rep);
        else
            addToast(rep.error_message, { appearance: 'error' });
        setLoaded(true);
    };

    return [elements, loaded, _fetch];
};

export default useElements;