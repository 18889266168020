import React from "react";
import Container from "reactstrap/es/Container";
import Section from "../../../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import InternalAd from "../../../components/blocks/InternalAd";

const C = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Envoi d'un courrier avec horodatage certifié eIDAS en ligne - simple et rapide</title>
        <meta
          name={"description"}
          content={
            "La procédure d'envoi d'un mail recommandé avec horodatage certifié eIDAS en ligne est simple, pratique et rapide, recevez automatiquement votre preuve de dépôt"
          }
        />
      </Helmet>
      <Section top>
        <h1>Envoi d'un courrier électronique en ligne</h1>

        <p></p>
        <h3>L'envoi de courrier avec horodatage certifié eIDAS en ligne, pratique et sûr.</h3>
        <p>Simplifiez vos envois de courrier certifié par horodatage :</p>
        <ul>
          <li>Chargez vos pièces jointes et rédigez un texte d'accompagnement.</li>
          <li>Saisissez les coordonnées de vos destinataires : nom et e-mail.</li>
          <li>Si vous leur avez déjà écrit, cliquez simplement sur leur nom.</li>
          <li>Vérifiez votre envoi, vos destinataires et le coût.</li>
          <li>Vous pouvez revenir faire des modifications à tout moment.</li>
          <li>Si votre message comporte des pièces jointes, réglez par carte bancaire ou par PayPal.</li>
          <li>Vous recevez automatiquement votre preuve de dépôt.</li>
          <li>
            Votre destinataire reçoit un avis de réception de courrier certifié par horodatage.&nbsp;S'il est d'accord pour la
            recevoir, il dispose d'un délai de 15 jours pour en prendre connaissance.
          </li>
        </ul>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default C;
