import React from "react";
import { Route } from "react-router-dom";

import Redaction from "../scenes/Envoi/Redaction";
import Destinataire from "../scenes/Envoi/Destinataire";
import EnvoiLogin from "../scenes/Envoi/EnvoiLogin";
import Verification from "../scenes/Envoi/Verification";
import Accepted from "../scenes/Accepted";
import Declined from "../scenes/Declined";
import Confirmation from "../scenes/Envoi/Confirmation";
import AcceptedBackward from "../scenes/AcceptedBackward";
import DeclinedBackward from "../scenes/DeclinedBackward";
import CodeSend from "../scenes/CodeSend";
import CodeEnter from "../scenes/CodeEnter";


const EnvoiRoutes = [
    <Route path="/envoyer-mail-recommande" exact render={(props) => <Redaction {...props} />} key={0} />,
    <Route path="/envoi-destinataire" exact render={(props) => <Destinataire {...props} />} key={1} />,
    <Route path="/envoi-login" exact render={(props) => <EnvoiLogin {...props} />} key={2} />,
    <Route path="/envoi-verification" exact render={(props) => <Verification {...props} />} key={3} />,
    <Route path="/envoi-confirmation" exact render={(props) => <Confirmation {...props} />} key={4} />,
    <Route path="/reco/accept/:recipient_id/:recipient_token" exact render={(props) => <Accepted {...props} />} key={5} />,
    <Route path="/reco/accept" exact render={(props) => <AcceptedBackward {...props} />} key={6} />,
    <Route path="/reco/decline/:recipient_id/:recipient_token" exact render={(props) => <Declined {...props} />} key={7} />,
    <Route path="/reco/decline" exact render={(props) => <DeclinedBackward {...props} />} key={8} />,
    <Route path="/reco/code/send/:recipient_id/:recipient_token" exact render={(props) => <CodeSend {...props}/>}  key={9} />,
    <Route path="/reco/code/enter/:recipient_id/:recipient_token" exact render={(props) => <CodeEnter {...props}/>}  key={10} />
];

export default EnvoiRoutes;
