import React from 'react';
import styled from 'styled-components';

const ConsulterWrapper = styled.div`

`;

const Consulter = ({...props}) => {

    return <ConsulterWrapper>

    </ConsulterWrapper>;
};

export default Consulter;