import React, { useEffect, useState } from "react";
import Container from "reactstrap/es/Container";
import Alert from "reactstrap/es/Alert";
import Section from "../components/Section";
import api from "../utils/api";
import InternalAd from "../components/blocks/InternalAd";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DeclinedBackward = ({ ...props }) => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  let query = useQuery();

  useEffect(() => {
    _doAccept();
  }, []);

  const _doAccept = async () => {
    let rep = await api.post(`/emails/${query.get("tk")}/recipients/${query.get("rtk")}/decline`, {
      email_token: query.get("tk"),
    });

    setLoaded(true);

    if (rep.error_message) setError(rep.error_message);
    else if (typeof window.gtag !== "undefined")
      window.gtag("event", "declined", {
        event_category: "email",
        event_label: props.match.params.recipient_id,
        value: 1,
      });
  };
  return (
    <Container>
      <Section top>
        <Helmet>
          <title>Refus de votre email avec horodatage certifié eIDAS</title>
          <meta name={"description"} content={"Refus de votre mail recommandé avec horodatage certifié eIDAS."} />
        </Helmet>
        <h1>Refus de votre email certifié par horodatage</h1>
        {loaded && (
          <div>
            {error ? (
              <div>
                <Alert className={"alert-danger"}>{error}</Alert>
              </div>
            ) : (
              <div>
                <Alert className={"alert-danger"}>Vous avez refusé un mail recommandé avec horodatage certifié eIDAS.</Alert>
              </div>
            )}
          </div>
        )}
        <Section>
          <InternalAd />
        </Section>
      </Section>
    </Container>
  );
};

export default DeclinedBackward;
