import React from 'react';
import useCached from "../utils/useCached";
import {Helmet} from "react-helmet/es/Helmet";
import Container from "reactstrap/es/Container";
import Section from "../components/Section";
import InternalAd from "../components/blocks/InternalAd";

const BlogPost = ({...props}) => {
    const slug = props.match.params.slug;
    let [post = {}, loaded] = useCached(`/blog/posts/${slug}`, {}, slug);

    if(!loaded)
        return <div/>;

    return <Container>
        <Helmet>
            <title>{post.title}</title>
            <meta name='description' content={post.excerpt} />
        </Helmet>
        <Section top>
            <div
                dangerouslySetInnerHTML={{__html:post.content}}
            />
            <InternalAd/>
        </Section>
    </Container>;
};

export default BlogPost;