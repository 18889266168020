import React from 'react';
import {Button as RSButton} from 'reactstrap';
import styled from "styled-components";
import {device} from "../utils/constants";

const RSButtonWrapper = styled(RSButton)`
  @media ${device.xs}{
    width:100%;
  }
`;

const Button = ({onClick, children, color='primary', center=false, right = false, disabled=false, containerStyle={}, ...props}) => {
    return <div style={{textAlign:right?'right':center?'center':'left', ...containerStyle}}>
        <RSButtonWrapper disabled={disabled} onClick={onClick} color={color} type="button" {...props}>
            {children}
        </RSButtonWrapper>
    </div>;
};

export default Button;