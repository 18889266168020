import React from 'react';
import Container from "reactstrap/es/Container";


import Section from "../../components/Section";
import SignupLogin, {SIGNUP} from "../../components/blocks/SignupLogin";


const EnvoiLogin = ({...props}) => {
    return <Container>
        <Section top>
            <SignupLogin
                onLogin={()=>props.history.push('/envoi-destinataire')}
                onSignup={()=>props.history.push('/envoi-destinataire')}
                defaultState={SIGNUP}
            />
        </Section>
    </Container>;
};

export default EnvoiLogin;