import React, {useContext} from 'react';
import {UserContext} from "../../contexts/user";
import Spinner from "reactstrap/es/Spinner";
import Container from "reactstrap/es/Container";
import Section from "../Section";
import { useHistory } from 'react-router-dom'

const CheckAuth = ({children, level=1, path = '/connexion', ...props}) => {
    let {user, logged_in, fetched} = useContext(UserContext);
    let history = useHistory();

    if(!fetched) return <Container>
        <Section style={{textAlign:'center'}}>
            <Spinner animation="border" role="status">
                Loading...
            </Spinner>
        </Section>
    </Container>;

    if(!logged_in || (level>1&&user&&!user.is_admin)){
        history.push(path);
        return <div/>;
    }

    return children;
};

export default CheckAuth;
