import React, {useState} from 'react';

export const VerificationContext = React.createContext(
  {}
);

export const VerificationContextProvider =({...props})=>{
    const [destination, setDestination] = useState("");

    
    return <VerificationContext.Provider
        value={{
            destination,
            setDestination,
        }}
        {...props}
    >

    </VerificationContext.Provider>
};


