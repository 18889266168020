import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";

const CGV = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Conditions générales de vente du courrier électronique avec horodatage certifié eIDAS</title>
        <meta
          name={"description"}
          content={
            "Mail Certificate, service d’envoi de mails électronique avec horodatage certifié eIDAS, permet la transmission, la traçabilité et la conservation de courriers électroniques certifiés par horodatage et confidentiels."
          }
        />
      </Helmet>
      <Section top>
        <h1>Conditions générales de vente</h1>
        <p>
          <strong>
            <b>Article 1</b> - Définitions
          </strong>
        </p>
        <blockquote>
          <p>
            Mail Certificate, service d’envoi de courrier électronique avec horodatage certifié eIDAS, (ci-après nommé ‘Mail
            Certificate’) permet la transmission, la traçabilité et la conservation de courriers électroniques avec horodatage
            certifié eIDAS et confidentiels. Il a vocation a établir une preuve des échanges grâce à un certificat sécurisé. Les
            utilisateurs sont informés que, le Mail Certificate ne réalise aucune matérialisation des échanges électroniques et
            par voie de conséquence aucune distribution postale.
            <br />
            <br />
            Le courrier électronique avec horodatage certifié eIDAS (ci-après appelé « courriel ») est document informatisé qu'un
            utilisateur saisit, envoie ou consulte en différé par l'intermédiaire d'un réseau internet. Le courriel contient
            &nbsp;du texte et/ou &nbsp;des pièces jointes. Il est mis à disposition de destinataires identifiés par leur adresse
            email. Le courriel contient un rapport d’état décrivant l’historique des événements&nbsp;: dépôt, n° d’identification
            de dépôt, heure d’envoi, heure de réception. <br />
            <br />
            L'identité représente une personne physique ou morale, Une personne morale est représentée par son représentant légal
            ou par un employé dûment autorisé à utiliser le service.
            <br />
            <br />
            L’identifiant et le mot de passe de l’utilisateur, définis par lui-même au moment de son inscription, permettent au
            service d’administration Mail Certificate de reconnaître l’utilisateur au moment où il se connecte à l’application, et
            de conserver la trace des courriels qu’il a envoyé.&nbsp;
            <br />
            <br />
            L’inscription est la procédure par laquelle l’utilisateur enregistre son identité et ouvre un compte lui permettant
            d’accéder au service Mail Certificate. La procédure d’inscription est validée après la consultation et l’acceptation
            par l’utilisateur des présentes conditions générales.
            <br />
            <br />
            L’utilisateur est une identité qui a procédé à son inscription et qui n’a pas clôturé son compte. Il dispose d’un
            compte lui permettant d'envoyer et de consulter les courriels envoyés.&nbsp;
            <br />
            <br />
            Le client est un utilisateur qui a effectué au moins un achat de courriels. Le client dispose d’un accès complet à
            l’application lui permettant d’envoyer et d’archiver des courriers.
            <br />
            <br />
            Les courriels achetés et l’espace d’archivage correspondent aux unités de paiement nécessaires à l’envoi et à
            l’archivage des courriers. Les conditions d’envoi et d’archivage associées aux différents courriers ainsi que leurs
            tarifs sont indiqués à l’adresse mail-certificate.com/tarifs.&nbsp;
            <br />
            <br />
            L’email utilisateur est l’adresse email au nom de l’utilisateur, fournie au moment de son inscription. Il est destiné
            à supporter la communication avec l’utilisateur.&nbsp;
            <br />
            <br />
            La durée de sauvegarde est le temps pendant lequel chaque courriel est archivé. Elle légalement de un an à minima.
          </p>
        </blockquote>
        <p>&nbsp;</p>
        <p>
          <strong>
            <b>Article 2</b> - Objet
          </strong>
        </p>
        <blockquote>
          <p>
            Les présentes conditions générales de service régissent les relations entre les utilisateurs et Mail Certificate,
            d'une part, et entre les utilisateurs eux-mêmes, d'autre part, en vue de l’utilisation de l’application et du service.
            Elles ont pour objet de définir les conditions dans lesquelles l’utilisateur s’inscrit au service et accède à
            l’application. Elles prévalent sur toute plaquette, brochure, document commercial, publicité ou contenu de site
            Internet.
            <br />
            L’utilisateur déclare en avoir pris connaissance et les accepter sans restriction ni réserve, cette acceptation se
            matérialisant par son inscription telle que décrite à l’article 5.
          </p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 3</b> - Mail Certificate
          </strong>
        </p>
        <blockquote>
          <p>
            Le service présenté ci-après est délivré à la demande des utilisateurs à partir des fonctions de la messagerie
            décrites à l’article 4 des présentes conditions générales.
          </p>
          <p>
            <br />
            <strong>3.1 Description du service</strong>
            <br />
            L’expéditeur identifié saisit son message et lui adjoint les éventuelles pièces jointes. Il saisit ensuite les
            coordonnées du destinataire&nbsp;: nom, société, adresse, adresse email. Les destinataires d’un courriel sont informés
            par email de l’envoi du courriel qui leur est destiné.&nbsp;
            <br />
            Conformément à la législation, le courriel doit être accepté et ouvert dans un délai de 15 jours suivant le jour de
            l'envoi. A l’issue de cette période, si le destinataire a déjà ouvert son courrier, il pourra le rouvrir et ce tant
            que le courrier sera disponible en ligne. Inversement, s’il n’a pas ouvert son courrier dans les délais, le
            destinataire ne pourra plus jamais accéder à son contenu (texte et/ou pièces jointes).&nbsp;
            <br />
            En cas de refus ou de non retrait du courriel par le destinataire, l’envoi par voie postale est à la charge de
            l’expéditeur.
            <br />
            Mail Certificate garde la trace de tout courriel envoyé à partir du site mail-certificate.com, et ce même si le
            destinataire n’a pas souhaité le recevoir pour faire valoir ce que de droit.&nbsp;
            <br />
            Les envois sont journalisés et archivés par Mail Certificate pour une durée, égale à la durée de sauvegarde, pendant
            laquelle, en cas de contestation par un utilisateur, Mail Certificate sera en mesure d’attester et de témoigner de la
            réalité des événements.&nbsp;
            <br />
            <br />
            Le contenu du courriel (texte et/ou pièces jointes) est maintenu en ligne, pour tous les correspondants, pendant une
            période de un an. A l'issue de cette durée, le courrier est supprimé des serveurs Mail Certificate et son contenu
            n’est plus accessible.
            <br />
            <br />
            Certification des échanges : Mail Certificate assure le rôle de témoin des échanges effectués entre les utilisateurs
            conformément à sa politique de service.
          </p>
          <p>
            <strong>3.2 Convention de preuve</strong>
            <br />
            En s’inscrivant au service Mail Certificate, l’utilisateur déclare accepter la présente convention de preuve
            constituée des dispositions qui suivent. L’utilisateur est avisé que les autres utilisateurs inscrits ont eux aussi
            accepté la présente convention de preuve et il s’engage à respecter le présent article.
            <br />
            <br />
            L'utilisateur s’engage à ne pas contester le contenu et les caractéristiques d’un courriel au seul motif que ce
            courrier est échangé sur un support électronique.
            <br />
            <br />
            L’utilisateur accepte qu’en cas de litige, les informations enregistrées par le Mail Certificate décrites à l’article
            7.2 soient admissibles devant les tribunaux et fassent preuve des données et des faits qu’elles contiennent.
          </p>
        </blockquote>
        <p>&nbsp;</p>
        <p>
          <br />
          <strong>
            <b>Article 4</b> - l’application Mail Certificate
          </strong>
        </p>
        <blockquote>
          <p>
            L’application permet la mise en œuvre du service décrit à l’article 3.1.
            <br />
            <br />
            <strong>4.1 Gestion des courriels</strong>
            <br />
            Connexion : l’utilisateur se connecte sur le site mail-certificate.com en saisissant son adresse email et son mot de
            passe.
            <br />
            <br />
            Envoi de courrier : l’utilisateur renseigne les données concernant son message, soit le texte et éventuellement les
            pièces jointes, et les coordonnées du ou des destinataires.
            <br />
            <br />
            Pièces jointes : Le poids des pièces jointes ne peut excéder le poids autorisé par les logiciels de messageries
            utilisés.&nbsp;
            <br />
            Réception et ouverture d’un email : pour avertir le destinataire que le courrier électronique avec horodatage certifié
            eIDAS lui est adressée, Mail Certificate déclenche l’envoi d’un email de notification à l’adresse e-mail donnée par
            l’expéditeur. Le destinataire peut accepter et ouvrir, ou refuser le courrier éléctronique avec horodatage certifié
            eIDAS. Pour ouvrir le courrier avec horodatage certifié eIDAS, le destinataire doit cliquer sur le lien d’acceptation
            présent dans le mail.
            <br />
            <br />
            Notifications par email : Mail Certificate déclenche l’envoi par email d’une notification à l’utilisateur dès qu’un
            évènement survient, comme un avis d’ouverture par un destinataire.
          </p>
          <p>
            <br />
            <strong>4.2 Gestion des données de l’utilisateur&nbsp;</strong>
            <br />
            Compte : ce module permet à l’utilisateur de maintenir ses informations personnelles et professionnelles à jour, tel
            que mentionnées à l’article 8.1 des présentes conditions, et d’ajouter des expéditeurs à son compte.
          </p>
        </blockquote>
        <p>&nbsp;</p>
        <p>
          <br />
          <strong>
            <b>Article 5</b> - Inscription et ouverture de compte
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            <strong>5.1 Formulaire d’inscription</strong>
            <br />
            L’ouverture d’un compte nécessite la fourniture d'informations permettant l'identification de l'utilisateur,
            renseignées dans le formulaire d'inscription.
            <br />
            <br />
            L’utilisateur garantit la véracité et l'exactitude des informations mises en ligne dans le formulaire d’inscription.
            Il engage sa responsabilité quant à l'inexactitude de ces informations pouvant induire en erreur les autres
            utilisateurs quant à son identité véritable en tant qu’expéditeur d’un courrier et de nature à falsifier l'origine et
            l’authenticité d’un courrier.
            <br />
            <br />
            L’utilisateur s’engage à maintenir ces informations à jour comme mentionné à l’article 8.1.
          </p>
          <p>
            <br />
            <strong>5.2 Vérification de l’email principal</strong>
            <br />
            Après validation du formulaire d’inscription, Mail Certificate déclenche une procédure permettant de vérifier que
            l’adresse email indiquée dans le formulaire est valide et que l’utilisateur accède bien aux messages qui lui sont
            transmis à cette adresse. La responsabilité de Mail Certificate ne peut être retenue si la procédure de vérification
            échoue en raison d'une erreur de saisie de son adresse email ou en raison de problèmes techniques liés au
            fonctionnement du réseau Internet.
          </p>
          <p>
            <br />
            <strong>5.3 Mot de passe</strong>
            <br />
            L’utilisateur est invité à saisir un mot de passe en double saisie pour s’assurer qu’il n’y a pas d’erreur. Mail
            Certificate ne conserve pas le mot de passe de l’utilisateur. Il est de la responsabilité de l’utilisateur de le
            conserver en mémoire et d’en préserver le caractère confidentiel.
          </p>
          <p>
            <br />
            <strong>5.4 Validation de l’inscription</strong>
            <br />
            Dès lors que l’utilisateur a accepté les termes du présent contrat et validé l’inscription il reconnaît que les
            présentes stipulations contractuelles lui sont opposables après qu'il a cliqué sur le bouton de confirmation de
            l’inscription pendant toute la durée d'utilisation du service, que cette utilisation soit intensive, régulière,
            exceptionnelle ou occasionnelle.
          </p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 6</b> - modalités de paiement
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Tous nos services sont sécurisés.
          </p>
          <p>
            <br />
            Les informations peuvent être conservées par notre partenaire Stripe pour faciliter les futurs achats à la demande du
            client. Ces informations ne transitent jamais par Mail-Certificate et son uniquement traitées par l'organisme
            bancaire.
          </p>
        </blockquote>
        <blockquote>
          <p>
            Aucun remboursement dans le cas d’erreur d’adresse mail du destinataire et systèmes antivirus du destinataire bloquant
            l’arrivée du mail.
          </p>
        </blockquote>
        <p>&nbsp;</p>
        <p>
          <strong>
            <b>Article 7</b> - Droits et obligations de Mail Certificate
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            <strong>7.1 Dispositions relatives à la protection de la vie privée</strong>
            <br />
            Mail Certificate respecte les lois françaises et européennes relatives à la protection de la vie privée.
            <br />
            <br />
            Mail Certificate protège les données que l’utilisateur est amené à lui communiquer et s’interdit d’utiliser, de céder
            ou de transférer à des tiers, à d’autres fins que la fourniture du service, les informations nominatives et la liste
            de contacts de l’utilisateur.
            <br />
            <br />
            L’utilisateur reconnaît que les courriers émis ou reçus par lui pourront être communiqués aux enquêteurs sur
            réquisition des autorités judiciaires.
          </p>
          <p>
            <br />
            <strong>7.2 Fiabilité des informations</strong>
            <br />
            Le Mail Certificate permet d'enregistrer et de mémoriser, de façon exacte et fiable, les caractéristiques des
            courriers échangés, à savoir :<br />- l'identifiant de l'utilisateur qui a constitué et expédié le courrier,
            <br />- l'authenticité du contenu du courrier par son empreinte numérique,
            <br />- l'identifiant d'Mail Certificate en tant que tiers d'acheminement du courrier,
            <br />- les dates et heures des événements (constitution, expédition, ouverture).
            <br />
            <br />
            Mail Certificate ne modifie pas ces informations et s'engage à mettre en œuvre les moyens techniques nécessaires à la
            conservation de ces informations et à en permettre la restitution, aussi bien en langage clair (affichage des
            attestations des courriers) que sous forme de données informatiques (restitution des données).
          </p>
          <p>
            <br />
            <strong>7.3 Maintenance, tests et améliorations</strong>
            <br />
            Mail Certificate s'engage à déployer ses meilleurs efforts pour garantir le bon fonctionnement de l’application et du
            service.
            <br />
            <br />
            Mail Certificate se réserve le droit de suspendre le fonctionnement de l’application et du service pour des raisons de
            test, d’audit, de travaux d’amélioration du trafic réseau, de maintenance, de mises à jour des serveurs ou en cas de
            panne ou menace de panne et ce sans être tenu à aucune obligation d’information ni garantie, indemnité ou dommage
            intérêt d’aucune sorte vis-à-vis des utilisateurs.
            <br />
            <br />
            L’utilisateur bénéficie automatiquement des nouvelles mises à jour de l’application.
          </p>
          <p>
            <br />
            <strong>7.4 Support technique et commercial</strong>
            <br />
            L’utilisateur bénéficie d’un support technique par email à l’adresse contact@mail-certificate.com
          </p>
          <p>&nbsp;</p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 8</b> - Droits et obligations de l’utilisateur
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            <strong>8.1 Identification</strong>
            <br />
            La fourniture par l’utilisateur des informations relatives à son identité, sincères et véritables, et leur maintien à
            jour sont des conditions déterminantes du droit d'utiliser l’application et le service.
            <br />
            <br />
            L’utilisateur engage sa responsabilité quant à l'inexactitude des informations relatives à son identité, ces
            informations pouvant induire en erreur les autres utilisateurs quant à son identité véritable en tant qu’expéditeur ou
            destinataire d’un courrier et de nature à falsifier l'origine et l’authenticité d’un courrier.
          </p>
          <p>
            <br />
            <strong>8.2 Mot de passe</strong>
            <br />
            Le mot de passe de l’utilisateur lui est personnel et confidentiel. Il s'interdit donc expressément de le communiquer
            à des tiers et s'engage à mettre tout en œuvre en vue d’assurer sa non-divulgation.
            <br />
            <br />
            L’utilisateur ne peut partager ou céder son compte à qui que ce soit. Cela constitue une obligation essentielle des
            présentes conditions générales. S’il ouvre une session sur un ordinateur public, il doit veiller à fermer cette
            session et à vider le cache du navigateur qui lui a servi à se connecter (Explorer, Safari, Chrome, Firefox, etc.)
            lorsqu’il quitte cet ordinateur.
            <br />
            <br />
            L’utilisateur est responsable de toute utilisation frauduleuse qui résulterait du non-respect de ces procédures. Toute
            transaction effectuée au moyen de l'identifiant et du mot de passe de l’utilisateur est réputée effectuée par
            l’utilisateur. En cas d'utilisation frauduleuse de son compte, l’utilisateur sera seul et entièrement responsable des
            conséquences qui pourraient en découler.
            <br />
            <br />
            L’utilisateur peut modifier (réinitialiser) à tout moment son mot de passe.
            <br />
            <br />
            En cas d’utilisation frauduleuse de son compte, il appartiendra à l’utilisateur de réinitialiser immédiatement son mot
            de passe (celui-ci ne pouvant être réinitialisé qu’à sa demande exclusive) et de signaler à Mail Certificate
            l’utilisation frauduleuse. Toute information tardive est considérée comme fautive.
          </p>
          <p>
            <br />
            <strong>8.3 Perte des moyens d’accès au compte</strong>
            <br />
            En cas d’oubli de son mot de passe, l’utilisateur peut demander à réinitialiser son mot de passe, dont le lien de
            confirmation de changement de mot de passe lui sera envoyé à son adresse e-mail.
            <br />
            <br />
            <br />
            <strong>8.4 Respect des délais légaux et réglementaires</strong>
            <br />
            Il relève exclusivement de la responsabilité du client d’effectuer ses envois et ouvertures de courriers à temps, de
            sorte que les éventuels délais en cours puissent être encore respectés même en cas d’interruption du service.
          </p>
          <p>
            <br />
            <strong>8.5 Prérequis techniques</strong>
            <br />
            L'utilisateur de Mail Certificate doit disposer d'une installation technique adéquate dont les caractéristiques
            minimales requises sont les suivantes :<br />- un ordinateur connecté à Internet de manière à garantir une utilisation
            satisfaisante d'un navigateur web respectueux des standards, autorisant l’exécution de modules JavaScript,
            <br />
            ou
            <br />- un smartphone permettant l’accès à internet, disposant d’un réseau de capacité de débit suffisante&nbsp; et
            permettant l’envoi de pièces jointes
            <br />- une adresse email, avec accès permanent aux messages reçus à cette adresse.
            <br />
            <br />
            L’utilisateur déclare connaître l’utilisation d’internet et accepter ses caractéristiques et ses limites, en
            particulier ses performances techniques et temps de réponse pour consulter, interroger, transférer ou télécharger des
            informations.
            <br />
            <br />
            L’utilisateur de Mail Certificate déclare et reconnaît que :&nbsp;
            <br />- les transmissions de données sur Internet ne bénéficient pas d'une fiabilité technique absolue, celles-ci
            circulant sur des réseaux hétérogènes aux caractéristiques et capacités techniques diverses, qui sont parfois saturés
            à certaines heures de la journée ;<br />- tout dysfonctionnement lié à un mauvais fonctionnement ou souci technique
            des infrastructures utilisées par Mail Certificate ou de l’installation personnelle de l’utilisateur (matériel
            informatique, connexion Internet…) ne saurait être imputé à Mail Certificate ;<br />- les notifications envoyées par
            email à l’utilisateur dans le cadre des services ne sont pas protégées contre des détournements éventuels. Il lui
            appartient de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la
            contamination par d'éventuels virus circulant au travers des données échangées.
          </p>
          <p>
            <br />
            <strong>8.6 Collaboration</strong>
            <br />
            En cas de réclamation, de plainte émanant de tiers ou d'action judiciaire formée à l'encontre de Mail Certificate, ses
            représentants, salariés ou partenaires, du fait de l’utilisation de l’application et des services ou consécutive à la
            transmission de tout contenu véhiculé dans un courrier, l’utilisateur s’engage à assister Mail Certificate pour
            remédier à un dysfonctionnement ou à un mauvais usage des services, en lui communiquant tous documents, données ou
            informations utiles à cette fin.
          </p>
          <p>
            <br />
            <strong>8.7 Propriété intellectuelle</strong>
            <br />
            Le site internet mail-certificate.com, la marque et le logo Mail Certificate, la charte graphique du site et de
            l’application visés par les présentes conditions générales sont la propriété exclusive de Mail Certificate qui détient
            tous les droits de propriété intellectuelle et industrielle applicables.&nbsp;
            <br />
            <br />
            L’utilisateur s'engage à utiliser l’application et les services exclusivement pour ses besoins propres ou ceux de son
            entreprise. Il lui est interdit de reproduire, vendre, concéder sous licence, distribuer, céder ou transférer tout ou
            partie des services et de l’application auxquels il pourrait avoir accès.
          </p>
          <p>&nbsp;</p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 9</b> - Modification des services, de l’application et des conditions générales
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Mail Certificate se réserve la faculté de modifier en tout temps les présentes conditions générales et d'apporter au
            service et à l’application toute modification qu'elle jugerait utile ou nécessaire.
            <br />
            <br />
            Les modifications seront portées à la connaissance de l’utilisateur avant leur entrée en vigueur par notification
            affichée sur la page de connexion à l’application et sur le site mail-certificate.com, l’invitant à se référer à la
            dernière version des conditions générales disponible en permanence sur le site.
            <br />
            <br />
            L’utilisateur qui n'acceptera pas ces modifications pourra clôturer son compte selon la procédure décrite à l'article
            12.1 des présentes conditions générales. En l'absence d'une telle clôture, les conditions générales modifiées seront
            réputées acceptées et opposables à l’utilisateur à la date de leur entrée en vigueur.
          </p>
          <p>&nbsp;</p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 10</b> - Limites juridiques du service
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Il appartient à l’utilisateur d'adapter son utilisation du service et de l’application aux caractéristiques précises
            et concrètes de sa situation.
            <br />
            <br />
            L’utilisateur reconnaît notamment que le service n’a pas pour objet de remplacer tous les courriers postaux, en
            particulier imposés par la loi ou les relations contractuelles.
            <br />
            <br />
            De même, l’utilisateur reconnaît que la détermination des effets juridiques des courriers envoyés et reçus par Mail
            Certificate dépend de la législation et de la pratique des tribunaux. L’utilisation de Mail Certificate, notamment à
            des fins de respect des délais, survient donc exclusivement aux risques et périls de l’utilisateur.
            <br />
            <br />
            L’utilisateur doit vérifier si un échange électronique est autorisé et quelles sont les conditions qui doivent être
            respectées. Lors de la prise en compte des délais, il faut tenir compte que des retards peuvent survenir dans le cas
            d’une transmission électronique.
            <br />
            <br />
            Les effets juridiques décrits dans les présentes conditions générales reposent sur le droit français.
          </p>
          <p>&nbsp;</p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 11</b> - Responsabilité
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            L’utilisateur déclare avoir connaissance de la nature de l'Internet, comme déclaré à l’article 8.5, et s’engage, en
            utilisant Mail Certificate, à ne pas porter atteinte à ses obligations contractuelles ou légales et notamment à ne pas
            expédier de messages contenant des virus ou pouvant être assimilés à du spam.
            <br />
            <br />
            La responsabilité de Mail Certificate ne pourra être engagée dans le cas où l'inexécution de ses obligations serait
            imputable à un cas fortuit ou de force majeure tel que défini par le Code civil et précisé par la jurisprudence.
            <br />
            <br />
            De même, la responsabilité de Mail Certificate ne saurait être engagée pour tous les inconvénients, obstacles ou
            dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou
            la présence de virus informatiques, situations étant assimilées à la force majeure. Mail Certificate prendra néanmoins
            toutes les précautions qu’il jugera nécessaires pour protéger ses propres serveurs informatiques.
            <br />
            <br />
            Mail Certificate ne peut être tenu responsable du non aboutissement des courriers en cas d’indisponibilité de l’email
            principal d’un destinataire. Mail Certificate n'est aucunement responsable des performances du serveur de messagerie
            du destinataire des courriers. La consultation et la gestion de l’email principal par l’utilisateur sont de sa
            responsabilité.
            <br />
            <br />
            De même Mail Certificate ne peut être tenu responsable du refus de procéder à l’ouverture d’un courrier reçu.
            <br />
            <br />
            Limite de responsabilité : En toute hypothèse, le montant total de la responsabilité éventuellement retenue à
            l'encontre de Mail Certificate ne pourra pas excéder la somme effectivement payée par l’utilisateur à Mail Certificate
            pendant l'année civile au cours de laquelle le fait générateur du dommage est survenu. Il appartient à l’utilisateur
            de limiter le montant de son préjudice dès la découverte de celui-ci en prenant les mesures appropriées.
          </p>
          <p>
            <br />
            Mail Certificate ne pourra être tenu responsable de l’arrivée du courrier dans un dossier «&nbsp;spam&nbsp;» ou
            «&nbsp;indésirable&nbsp;».
          </p>
        </blockquote>
        <p>&nbsp;</p>
        <p>
          <strong>
            <b>Article 12</b> - clôture de compte et restitution des données
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            <strong>12.1 Clôture de compte par l’utilisateur</strong>
            <br />
            L’utilisateur peut clôturer son compte en en faisant la demande à l’adresse contact@mail-certificate.com. Un mail de
            confirmation sera envoyé à l’utilisateur afin de vérifier que la demande émane bien de lui et non d’un robot ou d’un
            programme qui aurait usurpé son identité.
            <br />
            <br />
            Le compte de l’utilisateur sera alors supprimé, mais Mail Certificate conservera les données relatives à l’identité de
            l’utilisateur sans limitation de durée. Les courriels que l’utilisateur a échangés avec d’autres utilisateurs seront
            conservés pendant la durée légale de un an. &nbsp;
            <br />
            <br />
            La clôture du compte sera effective après un préavis de 90 (quatre-vingt-dix) jours francs suivant la confirmation de
            la prise en compte de la clôture par Mail Certificate. Durant ce préavis, l’utilisateur devra sauvegarder manuellement
            l’ensemble des données disponibles sur son compte.
            <br />
            <br />
            L’accès à son compte étant totalement libre, il est conseillé à l’utilisateur de ne pas le clôturer surtout s’il ne
            prévoit aucune sauvegarde de ses données.
          </p>
          <p>
            <br />
            <strong>12.2 Clôture de compte par Mail Certificate</strong>
            <br />
            En cas de manquement à l'une des obligations découlant des présentes conditions générales de service, et en
            particulier aux obligations d'identification stipulées à l’article 8.1, Mail Certificate peut adresser par lettre
            recommandé électronique à l’utilisateur une notification le mettant en demeure de remédier à ce manquement. Si la mise
            en demeure est restée sans effet pendant un délai de 15 (quinze) jours francs ou si Mail Certificate constate un
            nouveau manquement, Mail Certificate pourra, avec effet immédiat, suspendre le service et clôturer le compte.
            <br />
            <br />
            Dans le cas où Mail Certificate reçoit notification du décès de l’utilisateur, l’accès au compte est suspendu. Mail
            Certificate ne sera tenu d’en permettre l’accès ou d’en transférer le contenu à un ayant-droit ou à un autre tiers,
            que sur autorisation de justice ou au vue d’une requête valide du notaire chargé de la succession ou d’un mandataire
            habilité du ou des héritiers, accompagnée d’un certificat de notoriété.
            <br />
            <br />
            En cas de clôture de son compte par Mail Certificate pour l'une des raisons exposées ci-dessus l’utilisateur s’expose
            à la perte totale et définitive de toutes ses données.
          </p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 13</b> - Attribution de compétence territoriale
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Les présentes conditions générales de service sont régies par la loi française.
            <br />
            <br />
            En cas de contestation ou litige entre les parties à l’occasion de l’interprétation et/ou de l’exécution du présent
            contrat et, à défaut d’accord amiable, le litige ressort de la compétence du Tribunal de commerce de VERSAILLES pour
            l’utilisateur personne morale ou une personne physique commerçante, à la condition que le contrat soit accessoire à
            son activité commerciale et du Tribunal d'Instance ou Tribunal de Grande Instance compétent pour les personnes
            physiques.
          </p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 14</b> - Absence du droit de rétractation
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Les utilisateurs, clients du service Mail-certificate reconnaissent que les prestations ou services immédiats sont
            pleinement et complètements exécutés au moment de l’expédition, de l’envoi par l’expéditeur. Les utilisateurs, clients
            renoncent à tout droit de rétractation à cet égard et le confirme lors de l'inscription au site encochant la case «
            j’ai lu et j’accepte les CGV » Les moyens d’identification numérique étant personnalisés, l’utilisateur, le client
            renonce à tout droit.
          </p>
        </blockquote>
        <p>
          <br />
          <strong>
            <b>Article 15</b> - Médiateur de la consommation
          </strong>
        </p>
        <blockquote>
          <p>
            <br />
            Conformément aux dispositions du Code de la consommation et plus particulièrement aux articles L.612-1 et suivants,
            nous vous informons de l'existence d'un médiateur de la consommation compétent pour tout litige relatif à notre
            activité. En cas de litige avec notre société, vous pouvez recourir gratuitement au service de médiation suivant :
            <br />
            <ul>
              <li>
                <strong>Sas MEDIATION SOLUTION</strong>
              </li>
              <li>222 Chemin de la Bergerie</li>
              <li>01800 Saint Jean de Niost - France</li>
              <li>Tél.: 33(0)4 82 53 93 06</li>
              <li>Courriel: contact@sasmediationsolution-conso.fr</li>
              <li>Site: https://sasmediationsolution-conso.fr</li>
            </ul>
            Avant de saisir le médiateur, vous devez avoir préalablement tenté de résoudre le litige directement avec notre
            société par une réclamation écrite. La saisine du médiateur ne peut intervenir que si le litige n'a pas pu être résolu
            dans un délai raisonnable ou si vous n'avez pas obtenu de réponse de notre part dans un délai de 15 jours à compter de
            la réclamation initiale. Le médiateur aura pour mission de favoriser la résolution amiable du litige entre les
            parties, dans le respect des règles de procédure applicables. La décision rendue par le médiateur sera prise en compte
            par les parties et pourra, le cas échéant, faire l'objet d'une exécution forcée conformément à la loi. La saisine du
            médiateur de la consommation n'est pas obligatoire, et vous conservez le droit de recourir à une procédure judiciaire
            pour régler votre litige.
          </p>
        </blockquote>
      </Section>
    </Container>
  );
};

export default CGV;
