import React from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import { Row } from "../Grid";
import { Link } from "react-router-dom";
import BaseBlock from "../BaseBlock";
import Section from "../Section";

const BFooterWrapper = styled.footer``;

const BFooter = ({ ...props }) => {
  return (
    <BFooterWrapper>
      <div className={"footer-shaped"}>
        <Container>
          <Section>
            <div>
              <Row md>
                <div>
                  <Link to={"/courrier-en-ligne"}>Envoi courrier en ligne</Link>
                  <Link to={"/courrier-certifie"}>Envoyer un courrier certifiés par horodatage eIDAS</Link>
                  <Link to={"/envoi-courrier-certifie"}>
                    Envoi lettre recommandée électronique (LRE) avec horodatage certifié eIDAS
                  </Link>
                </div>
                <div>
                  <Link to={"/acccuse-reception"}>Courrier et Accusé de réception</Link>
                  <Link to={"/mentions-legales"}>Mentions Légales</Link>
                  <Link to={"/conditions-generales-vente"}>Conditions générales de vente</Link>
                </div>
                <div>
                  <Link to={"/a-propos"}>À propos</Link>
                  <Link to={"/nos-services"}>Nos services</Link>
                  <Link to={"/politique-confidentialite"}>Politique de confidentialité</Link>
                  <Link to={"/politique-cookies"}>Politique de cookies</Link>
                </div>
                <div>
                  <div>© 2020 Mail-certificate</div>
                  <div>contact@mail-certificate.com</div>
                  <div>
                    <a href="https://www.bloctel.gouv.fr/">Bloctel</a>
                  </div>
                </div>
              </Row>
            </div>
          </Section>
        </Container>
      </div>
    </BFooterWrapper>
  );
};

export default BFooter;
