import React from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import Alert from "reactstrap/es/Alert";
import { Link } from "react-router-dom";
import BaseBlock from "../../components/BaseBlock";

const ConfirmationWrapper = styled(Container)``;

const Confirmation = ({ ...props }) => {
  return (
    <ConfirmationWrapper>
      <Section top>
        <h1>Votre email a bien été envoyé</h1>
        <p>
          Un email a été envoyé à vos destinataires leur permettant d'accepter ou refuser de recevoir votre courrier avec
          horodatage certifié eIDAS.
        </p>
        <BaseBlock>
          <Alert color={"success"}>
            <i className={"fa fa-check"} /> &nbsp; <b>Vous serez averti de l'avancement par email.</b>
          </Alert>
        </BaseBlock>
        <div>
          Retrouvez également le statut de votre envoi dans votre <Link to={"/compte/historique-emails"}>espace client</Link>
        </div>
      </Section>
    </ConfirmationWrapper>
  );
};

export default Confirmation;
