import moment from "moment-timezone";
import "moment/locale/fr";
moment.locale("fr");

export function isDev() {
  // prod mode = return false
  return false;
  const hostname = typeof window !== "undefined" && window && window.location && window.location.hostname;
  let isDev = hostname === "localhost";
  return isDev;
}

export const testFileType = (fileName) => {
  if (!fileName) return false;

  let dots = fileName.split(".");
  let fileType = dots[dots.length - 1].toLowerCase();

  return !!~["png", "jpg", "pdf", "doc", "docx", "jpeg"].indexOf(fileType);
};

export const readableDate = (date) => {
  return moment.utc(date).clone().tz('Europe/Paris').format("dddd DD/MM/YYYY HH:mm");
};

export function isABVariant(type = "B") {
  if (typeof window === "undefined") return false;
  if (typeof window.abUserType === "undefined") return false;
  return window.abUserType === type;
}
