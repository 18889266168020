import React, {useContext} from 'react';
import InfoText from "../InfoText";
import {UserContext} from "../../contexts/user";
import {EmailContext} from "../../contexts/email";

const MailHeader = ({userInfos=false, ...props}) => {
    let {user, logged_in} = useContext(UserContext);
    let {recipients}  = useContext(EmailContext);

    if(userInfos){
        user=userInfos.user;
        logged_in=userInfos.logged_in;
        recipients=userInfos.recipients;
    }

    let recipient = !!recipients&&recipients.length?recipients[0]:false;

    return <div
        style={{padding:40}}
        className={'exprecheader'}
    >
        <div>
            {
                !!logged_in?
                    <div>
                        <div>{user.prenom} {user.nom}</div>
                        <div>{user.societe}</div>
                        <div>{user.email}</div>
                        <div>{user.adresse}</div>
                        <div>{user.cp} {user.ville}</div>
                    </div>:
                <div>
                    [EXPEDITEUR]
                    <br/>
                    <InfoText>
                        Sera complété à l'étape suivante
                    </InfoText>
                </div>
            }
        </div>
        <div style={{textAlign:'right'}}>
            {
                recipient?
                    <div>
                        <div>{recipient.prenom} {recipient.nom}</div>
                        <div>{recipient.societe}</div>
                        <div>{recipient.email}</div>
                        <div>{recipient.adresse}</div>
                        <div>{recipient.cp} {recipient.ville}</div>
                    </div>
                    :
                    <div>
                        <div id={'rplcble'}>[DESTINATAIRE]</div>
                    </div>
            }
        </div>
    </div>;
};

export default MailHeader;