import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Button from "../Button";
import InputGroup from "../InputGroup";
import { Row } from "../Grid";
import Checkbox from "../Checkbox";
import api from "../../utils/api";
import BaseBlock from "../BaseBlock";
import ErrorText from "../ErrorText";
import { validateFrenchPhoneNumber } from "../../utils/phoneValidation";

const SignupForm = ({ onSuccess, noSignup = false, errors = {}, mobilePlaceholer, ...props }) => {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [societe, setSociete] = useState("");
  const [adresse, setAdresse] = useState("");
  const [cp, setCP] = useState("");
  const [ville, setVille] = useState("");
  const [tel, setTel] = useState("");
  const [telMobile, setTelMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cgv, setCGV] = useState(false);
  const [cgvError, setCGVError] = useState("");
  const [internalErrors, setErrors] = useState({});
  const [newsletter, setNewsletter] = useState(false);

  const handleChangeTelMobile = (phone) => {
    setTelMobile(phone);
    validateFrenchPhoneNumber(phone, (error) => {
      setErrors({ ...internalErrors, telMobile: error });
    });
  };

  const _submit = async (e) => {
    e.preventDefault();

    setCGVError("");
    if (!noSignup && !cgv) {
      setCGVError("Vous devez accepter les CGV afin de continuer.");
      return;
    }

    let data = {
      prenom,
      nom,
      societe,
      adresse,
      cp,
      ville,
      email,
      tel,
      telMobile,
    };

    if (noSignup) return onSuccess(data);

    data.password = password;
    data.newsletter = newsletter;

    try {
      const repValidation = await api.post("/users/validations", data);
      if (!repValidation.valid) {
        throw repValidation.errors;
      }
      const repVerification = await api.post("/users/verifications", { destination: telMobile });
      if (!repVerification.ok) {
        throw { telMobile: "Erreur de téléphone" };
      }
      onSuccess(data);
    } catch (error) {
      setErrors(error);
    }
  };

  let errorKeys = Object.keys(errors).toString();
  useEffect(() => {
    setErrors(errors === null ? {} : errors);
  }, [errorKeys]);

  return (
    <div>
      <Form onSubmit={_submit}>
        <Row>
          <InputGroup
            error={internalErrors.prenom}
            value={prenom}
            onChangeText={setPrenom}
            placeholder={"Prenom"}
            icon={"fa fa-user-o"}
          />
          <InputGroup error={internalErrors.nom} value={nom} onChangeText={setNom} placeholder={"Nom"} icon={"fa fa-user-o"} />
        </Row>
        <InputGroup
          error={internalErrors.societe}
          value={societe}
          onChangeText={setSociete}
          placeholder={"Entreprise"}
          icon={"fa fa-building-o"}
        />
        <InputGroup
          error={internalErrors.addresse}
          value={adresse}
          onChangeText={setAdresse}
          placeholder={"Adresse*"}
          icon={"fa fa-map-marker"}
        />
        <Row>
          <InputGroup
            error={internalErrors.cp}
            value={cp}
            onChangeText={setCP}
            placeholder={"Code postal*"}
            icon={"fa fa-map-marker"}
          />
          <InputGroup
            error={internalErrors.ville}
            value={ville}
            onChangeText={setVille}
            placeholder={"Ville*"}
            icon={"fa fa-map-marker"}
          />
        </Row>

        <InputGroup
          error={internalErrors.telMobile}
          value={telMobile}
          onChangeText={handleChangeTelMobile}
          placeholder={mobilePlaceholer}
          inputType={"tel"}
        />
        <InputGroup
          error={internalErrors.tel}
          value={tel}
          onChangeText={setTel}
          placeholder={"Téléphone fixe (facultatif)"}
          inputType={"tel"}
        />

        <InputGroup
          error={internalErrors.email}
          value={email}
          onChangeText={setEmail}
          placeholder={"Email*"}
          icon={"fa fa-envelope-o"}
        />
        {!noSignup && (
          <BaseBlock t={0}>
            <InputGroup
              error={internalErrors.password}
              value={password}
              onChangeText={setPassword}
              placeholder={"Mot de passe"}
              icon={"fa fa-unlock-alt"}
              type={"password"}
              name={"password"}
            />
            <BaseBlock>
              <Checkbox value={cgv} onChange={() => setCGV(!cgv)}>
                J'ai lu et j'accepte les{" "}
                <a target="_blank" href={"/conditions-generales-vente"}>
                  CGV
                </a>
              </Checkbox>
            </BaseBlock>
            <BaseBlock>
              <Checkbox value={newsletter} onChange={() => setNewsletter(!newsletter)}>
                J'accepte de recevoir les dernières informations de Mail-Certificate.
              </Checkbox>
            </BaseBlock>
            <div>
              <ErrorText>{cgvError}</ErrorText>
            </div>
          </BaseBlock>
        )}
        <Button right type={"submit"}>
          Enregistrer
        </Button>
        {!noSignup && (
          <BaseBlock>
            <small>
              En continuant, vous acceptez nos conditions générales et notre{" "}
              <a target="_blank" href={"/politique-confidentialite"}>
                politique de confidentialité
              </a>
              . Nous utilisons uniquement vos données personnelles pour vous offrir une expérience personnalisée, ainsi que pour
              mieux comprendre et améliorer notre service. Pour plus de détails,{" "}
              <a target="_blank" href={"/politique-cookies"}>
                cliquez ici.
              </a>
            </small>
          </BaseBlock>
        )}
      </Form>
    </div>
  );
};

export default SignupForm;
