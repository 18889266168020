import axios from 'axios';
import {isDev} from "./utils";
import * as Sentry from '@sentry/browser';

export const API_BASE_URL = isDev()?'http://localhost:3001':'https://api.mail-certificate.com';

const ax = axios.create({
    baseURL: API_BASE_URL,
    timeout: 1000000,
    withCredentials: true
});
ax.defaults.headers.get['Pragma'] = 'no-cache';
ax.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';
const api = {
    get: async (url, data)=>{
        try{
            return (await ax.get(url, {params:data})).data
        }catch(e){
            return !!e.response?e.response.data:{error_message:'Erreur : '+e.message}
        }
    },
    post:async (url, data)=>{
        try{
            return (await ax.post(url, data)).data
        }catch(e){
            return !!e.response?e.response.data:{error_message:'Erreur : '+e.message}
        }
    },
    put:async (url, data)=>{
        try{
            return (await ax.put(url, data)).data
        }catch(e){
            return !!e.response?e.response.data:{error_message:'Erreur : '+e.message}
        }
    },
    delete:async (url, data)=>{
        try{
            return (await ax.delete(url, data)).data
        }catch(e){
            return !!e.response?e.response.data:{error_message:'Erreur : '+e.message}
        }
    },
    postFiles: async (url, files, progressCallback = ()=>null) => {
        try{
            const formData = new FormData();
            files.forEach(file=>{
                formData.append('attachments', file);
            });

            const config = {
                onUploadProgress: function(progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    progressCallback(percentCompleted);
                }
            }

            return (await ax.post(url, formData, config)).data
        }catch (e) {
            progressCallback(0);
            Sentry.captureException(e);
            return !!e.response?e.response.data:{error_message:'Erreur : '+e.message}
        }
    }
};

export default api;