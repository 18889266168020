import React, { useState } from "react";
import BaseBlock from "../BaseBlock";
import { Row } from "../Grid";
import Checkbox from "../Checkbox";
import useElements from "../../utils/useElement";
import AddContactModal from "../../modals/AddContactModal";
import Button from "../Button";
import api from "../../utils/api";
import moment from "moment";
import Input from "reactstrap/es/Input";
import { InputGroupAddon, InputGroupText } from "reactstrap";
import InputGroup from "reactstrap/es/InputGroup";

const ALPHA = "ALPHA",
  CHRONO = "CHRONO";

const firstLetter = (str) => {
  str = str.trim();
  return str.length > 0 ? str[0].toUpperCase() : "";
};

const ContactList = ({ selected = [], onSelect = false, rightElement = null, deletable = false, editable = false, ...props }) => {
  const [contacts = [], loaded, reload] = useElements("/contacts", { hide: false });
  const [contactModal, setContactModal] = useState(false);
  const [sortType, setSortType] = useState(CHRONO);
  const [nameFilter, setNameFilter] = useState("");

  // TODO on delete/edit : filter recipients list to remove/update contacts.

  const renderPhoneLabel = (tel, telMobile) => {
    let label = "";
    if (tel) label += `Tél: ${tel}`;
    if (telMobile) {
      if (label) label += " / ";
      label += `Mobile: ${telMobile}`;
    }
    return label ? (
      <div>
        <em>{label}</em>
      </div>
    ) : null;
  };

  const _success = () => {
    setContactModal(false);
    reload();
  };

  const _delete = async (id) => {
    let confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer le contact ? Cela ne l'empêchera pas de recevoir les emails certifié par horodatages eIDAS déjà envoyés."
    );
    if (!confirmed) return;
    await api.put(`/contacts/${id}`, { hide: true });

    reload();
  };

  const _edit = async (id) => {
    /*await api.put(`/contacts/${id}`, {hide:true});

        reload();*/
  };

  if (!loaded) return <div />;

  let sortedContacts = [...contacts].sort((a, b) => {
    if (sortType === CHRONO) return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1;
    if (sortType === ALPHA) return a.display_name.localeCompare(b.display_name);
  });

  if (!!nameFilter)
    sortedContacts = sortedContacts.filter((c) => !!~c.display_name.toLowerCase().indexOf(nameFilter.toLowerCase()));

  return (
    <div>
      <AddContactModal open={contactModal} toggle={() => setContactModal(!contactModal)} onSuccess={_success} />
      <BaseBlock>
        <Row>
          <Button onClick={() => setContactModal(true)}>Enregistrer un nouveau contact</Button>
          {rightElement}
        </Row>
      </BaseBlock>

      {!contacts.length ? (
        <BaseBlock>Vous n'avez pas encore de contact dans votre carnet d'adresses</BaseBlock>
      ) : (
        <Row center>
          <div>
            {sortType === CHRONO ? (
              <a onClick={() => setSortType(ALPHA)}>Classer par ordre alphabétique</a>
            ) : (
              <a onClick={() => setSortType(CHRONO)}>Classer par ordre chronologique</a>
            )}
          </div>
          <div>
            <InputGroup className="input-group">
              <Input placeholder={"Rechercher un contact"} value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />
              <InputGroupAddon addonType="append" onClick={() => setNameFilter("")} style={{ cursor: "pointer" }}>
                <InputGroupText>
                  <i className={`ni ni-fat-remove`} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </Row>
      )}
      <div>
        {sortedContacts.map((c, idx) => {
          let value = !!~selected.indexOf(c.id);

          let doDisplayHeader =
            sortType === ALPHA &&
            (idx === 0 || firstLetter(c.display_name) !== firstLetter(sortedContacts[idx - 1].display_name));

          return (
            <BaseBlock t={2} b={2} key={c.id}>
              {doDisplayHeader && <b>{firstLetter(c.display_name)}</b>}
              <div
                className={`bordered ${!!onSelect && "clickable"} ${value && "border-success"}`}
                onClick={() => onSelect && onSelect(c)}>
                <Row xs center gutter={40}>
                  {onSelect && (
                    <div style={{ flex: 0 }}>
                      <Checkbox value={value} />
                    </div>
                  )}
                  <div>
                    <span className={"lead"}>
                      {c.prenom} {c.nom}
                    </span>
                    <div>
                      <b>{c.email}</b>
                    </div>
                    {renderPhoneLabel(c.tel, c.telMobile)}
                    <em>{c.societe}</em>
                  </div>
                  {(deletable || editable) && (
                    <div style={{ flex: 0, textAlign: "right" }}>
                      {deletable && <a onClick={() => _delete(c.id)}>supprimer</a>}
                      {editable && <a onClick={() => _edit(c.id)}>modifier</a>}
                    </div>
                  )}
                </Row>
              </div>
            </BaseBlock>
          );
        })}
      </div>
    </div>
  );
};

export default ContactList;
