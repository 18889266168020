import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";
import InternalAd from "../../components/blocks/InternalAd";

const NosServices = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Nos services</title>
        <meta
          name={"description"}
          content={
            "Avec le site mail-certificate, recevez sans supplément un accusé de réception pour tout courrier électroniqu avec horodatage certifié eIDAS envoyé."
          }
        />
      </Helmet>
      <Section top>
        <div className="hdrd">
          <h1>Nos services</h1>
          <div>
            <p className={"lead"}>
              Avec le site mail-certificate, recevez sans supplément un accusé de réception pour tout courrier électronique avec
              horodatage certifié eIDAS envoyé.
            </p>
            <b>
              Le site mail-certificate vous permet de bénéficier de services qui vous simplifieront vos tâches administratives.
            </b>
          </div>
        </div>
        <div className={"content"}>
          <h2>Un service disponible 24/7</h2>
          <div>
            <p>
              Le site internet mail-certificate est ouvert tous les jours de l'année, 7j/7 et 24h/24. Il permet ainsi l'envoi de
              courrier avec horodatage certifié eIDAS en ligne avec accusé de réception à tout moment.
            </p>
            <h2>Economie de déplacement</h2>
          </div>
          <div>
            <p>
              Bénéficiez d'un service de courrier avec horodatage certifié eIDAS en ligne sans vous déplacer et depuis tout lieu
              disposant d’une connection internet. Fini les pertes de temps dans les transports, plus de file d’attente pour
              envoyer votre courrier avec horodatage certifié eIDAS.
            </p>
            <h2>Economie d’argent</h2>
          </div>
          <div>
            <p>
              Les coûts de l’envoi d’un courrier avec horodatage certifié eIDAS en ligne sont inférieurs aux coûts des recommandés
              papier. Optez pour cette solution moins chère !
            </p>
            <h2>Economie de papier</h2>
          </div>
          <div>
            <p>
              Entrez dans une démarche écologique, n’utilisez le papier que si cela est vraiment nécessaire. Le site
              mail-certificate vous permet de faire un geste pour l’environnement. Scannez vos documents et envoyez-les avec
              accusé de réception, certification et c’est tout.
            </p>
            <h2>Envoi facile de tout document</h2>
          </div>
          <div>
            <p>
              Les pièces jointes peuvent être des documents PDF, Word, Excel ou des photos. Assurez-vous que votre destinataire
              ait les logiciels pour les lire.
            </p>
          </div>
        </div>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default NosServices;
