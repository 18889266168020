import { EmailContextProvider } from "./contexts/email";
import { ToastProvider } from "react-toast-notifications";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ScrollToTopOnMount from "./components/ScrollToTopOnMount";
import BNavbar from "./components/Navbars/BNavbar";
import Index from "./scenes/Home";
import SEORoutes from "./routes/SEORoutes";
import StaticRoutes from "./routes/StaticRoutes";
import EnvoiRoutes from "./routes/EnvoiRoutes";
import AccountRoutes from "./routes/AccountRoutes";
import Blog from "./scenes/Blog";
import ResetPassword from "./scenes/ResetPassword";
import BlogPost from "./scenes/BlogPost";
import Login from "./scenes/Login";
import Consulter from "./scenes/Consulter";
import BFooter from "./components/Footers/BFooter";
import { UserContextProvider } from "./contexts/user";
import React from "react";
import CookieConsent from "react-cookie-consent";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import { Helmet } from "react-helmet/es/Helmet";
import Signup from "./scenes/Signup";
import * as Sentry from "@sentry/browser";
import { VerificationContextProvider } from "./contexts/verification";
import { colors } from "./utils/constants";

Sentry.init({ dsn: "https://7aafde564f8c49808565ffb19feb7b8f@o376453.ingest.sentry.io/5197293" });

const ClientApp = ({ ...props }) => {
  return (
    <UserContextProvider>
      <EmailContextProvider>
        <VerificationContextProvider>
          <ToastProvider autoDismiss={true} placement="bottom-right">
            <Helmet>
              <title>Envoi de lettre recommandée électronique (LRE) avec horodatage certifié eIdas et accusé de réception</title>
              <meta
                name={"description"}
                content={
                  "Envoyez votre lettre recommandée électronique (LRE) avec horodatage certifié eIdas et accusé de réception !"
                }
              />
            </Helmet>
            <BrowserRouter>
              <ScrollToTopOnMount>
                <BNavbar />
                <div className={"page-device-cont"}>
                  <Switch>
                    <Route path="/" exact render={(props) => <Index {...props} />} />
                    {SEORoutes}
                    {StaticRoutes}
                    {EnvoiRoutes}
                    {AccountRoutes}
                    <Route path="/blog/" exact render={(props) => <Blog {...props} />} />
                    <Route path="/reset_password/:user_id/:token" exact render={(props) => <ResetPassword {...props} />} />
                    <Route path="/blog/:slug" exact render={(props) => <BlogPost {...props} />} />
                    <Route path="/connexion" exact render={(props) => <Login {...props} />} />
                    <Route path="/inscription" exact render={(props) => <Signup {...props} />} />
                    <Route path="/consulter-mail-recommande" exact render={(props) => <Consulter {...props} />} />
                    <Redirect to="/" />
                  </Switch>
                </div>
                <BFooter />
                <CookieConsent
                  location="bottom"
                  style={{ background: colors.primary, width: "calc(100% - 104px)" }}
                  buttonStyle={{ color: "white", backgroundColor: colors.success }}
                  contentStyle={{ flex: "1" }}
                  expires={150}
                  acceptOnScroll={true}
                  buttonText={"J'accepte"}
                  enableDeclineButton={true}
                  declineButtonText={"Je refuse"}
                  onDecline={() => {
                    window.close();
                  }}
                >
                  Nous utilisons des cookies respectueux de vos données personnelles. Ils nous permettent d’améliorer votre
                  expérience.
                </CookieConsent>
              </ScrollToTopOnMount>
            </BrowserRouter>
          </ToastProvider>
        </VerificationContextProvider>
      </EmailContextProvider>
    </UserContextProvider>
  );
};

export default ClientApp;
