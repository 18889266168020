import React, {useState} from 'react';
import styled from 'styled-components';
import {Form} from "reactstrap";
import api from "../../utils/api";
import InputGroup from "../InputGroup";
import Button from "../Button";

const ResetFormWrapper = styled.div`

`;

const ResetForm = ({...props}) => {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const _submit = async e=>{
            e.preventDefault();


        await api.get('/users/reset', {email});

        setSent(true);
    };

    return <ResetFormWrapper>
        {
            sent?
                <div>
                    Les instructions de réinitialisation vous ont été envoyées.
                    <br/>
                    Consultez vos mails et suivez les instructions afin de réinitialiser votre mot de passe.
                </div>:
                <div>
                    <Form
                        onSubmit={_submit}
                    >
                        <InputGroup
                            value={email}
                            onChangeText={setEmail}
                            placeholder={'Email*'}
                            icon={'fa fa-envelope-o'}
                            name={"email"}
                        />
                        <div className="form-group">
                            <Button
                                right
                                type={'submit'}
                            >
                                Continuer
                            </Button>
                        </div>
                    </Form>
                </div>
        }
    </ResetFormWrapper>;
};

export default ResetForm;