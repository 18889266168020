import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";
import InternalAd from "../../components/blocks/InternalAd";

const PolitiqueConfidentialite = ({ ...props }) => {
    return (
        <Container>
            <Helmet>
                <title>Politique de confidentialité</title>
                <meta name={"description"} content={"En savoir plus sur la politique de confidentialité de Mail Certificate"} />
            </Helmet>
            <Section top>
                <div className="hdrd">
                    <h1>Politique de confidentialité</h1>
                    <br />
                    <p>
                        En conformité avec le Règlement Général sur la Protection des Données (RGPD), Mail Certificate vous assure de son engagement à respecter la confidentialité de vos informations
                        personnelles dans toutes ses opérations et services proposés.
                    </p>
                    <p>Cette politique décrit nos engagements et directives concernant la protection de vos Données Personnelles lorsque vous utilisez le site internet Mail Certificate.fr</p>
                </div>
                <h2>
                    <br />
                    Responsabilité du traitement
                </h2>
                <p>
                    Mail Certificate, est responsable du traitement de vos données. La Société Mail-certificate à son siège social au 37 A Avenue Egle 78600 Maisons-Laffitte. 952 868 230 RCS Versailles.
                    Responsble de publication M.Jacques LEVY Création, développement et design web Retour Internet 175 rue de la Leysse 73290 La Motte Servolex Hébergement du site Nuxit
                </p>
                <h2>Objectif du traitement</h2>
                <p>
                    Mail Certificate propose un site internet pour promouvoir ses offres et services et pour informer ses utilisateurs sur les actualités de l'entreprise.
                    <br /> Mail Certificate poursuit plusieurs sous-objectifs de traitement de données sur son site internet.
                    <br /> Outre l’activité spécifique de traitement de données concernant votre espace Mail Certificate, les objectifs de traitement de données sont les suivants :
                </p>

                <p> La gestion et l’envoi de mails aux différents services de Mail Certificate.</p>
                <ul>
                    <li>
                        La société <a href="https://www.certigna.com/">CERTIGNA</a>, certifié par horodatage eIDAS, est notre prestataire en charge d'apposer le cachet serveur sur les courriers électroniques
                        certifiés par horodatage. Ceci garanti l'intégrité du contenu et la date d'envoi du courrier électronique.
                    </li>
                </ul>

                <p>
                    Pour améliorer votre expérience sur notre site et réaliser des statistiques de visite, Mail Certificate utilise des cookies. Vous pouvez consulter notre politique de cookies, qui
                    explique comment nous traitons les cookies que nous recueillons et que vous nous fournissez : https://www.mail-certificate.fr/politique-cookies
                </p>
                <p>Notre site internet peut également vous rediriger vers des sites tiers. Les conditions de traitement de vos données sont spécifiées sur ces sites partenaires.</p>
                <h2>Fondement juridique du traitement des données</h2>
                <p>
                    La base légale pour le traitement de vos données personnelles est votre consentement, symbolisé par une case à cocher à la fin des formulaires ou un bouton « accepter » à côté d’un
                    bouton « refuser » pour les cookies. Dans le cas où vous ne donnez pas votre accord pour l'utilisation des cookies et la fenêtre de navigation sera fermée.
                </p>
                <p>
                    Si l'utilisateur n'effectue aucune action spécifique, Mail Certificate interprète cela comme une acceptation tacite de l'utilisation de ses cookies. Cette acceptation est alors
                    automatiquement déduite lorsque l'utilisateur fait défiler la page.
                </p>
                <h2>Données personnelles traitées</h2>
                <p>
                    Dans le cadre des objectifs de traitement, Mail Certificate peut traiter les ensembles de données personnelles liées directement et indirectement à ses utilisateurs. Les ensembles
                    de données personnelles incluent :
                </p>
                <ul>
                    <li>Informations sur l'identité des utilisateurs (Nom, Prénom, Adresse Mail, etc.) ;</li>
                    <li>Données de connexion (Adresse IP de l’utilisateur, Session ID) ;</li>
                    <li>Données relatives à la navigation sur le site internet de l’utilisateur (Titre des pages consultées, URL des pages, date de consultation, etc.).</li>
                </ul>
                <h2>Catégories de personnes impliquées dans le traitement</h2>
                <p>En raison de l'objectif poursuivi par ce traitement, les données traitées sur le site Mail Certificate concernent les prospects, les clients, et en général toutes les personnes.</p>
                <h2>Destinataires ou catégories de destinataires des données</h2>
                <p>
                    Compte tenu de l'objectif du traitement, les données traitées sur le site Mail Certificate sont destinées aux employés dédiés de Mail Certificate, en particulier ceux en charge de
                    la gestion du site internet.
                </p>
                <h2>Durée de conservation des données</h2>
                <p>
                    Les données personnelles des personnes concernées par les différents traitements de données sur le site internet sont conservées dans une base d’archive active (« Base active »)
                    pendant des durées variables en fonction de l'objectif poursuivi :
                </p>
                <ul>
                    <li>
                        Les données recueillies lors de l'envoi et de la réception des formulaires disponibles pour les utilisateurs sont conservées pendant des durées différentes en fonction des
                        personnes concernées :
                    </li>
                    <ul>
                        <li>Les données relatives à nos clients sont conservées pendant toute la durée de la relation précontractuelle et contractuelle ;</li>
                        <li>
                            Les données relatives aux prospects et aux visiteurs du site sont conservées pendant trois (3) ans à compter de leur collecte par le responsable de traitement ou du dernier
                            contact émanant du prospect (par exemple, une demande de documentation ou un clic sur un lien hypertexte contenu dans un courriel ; en revanche, l’ouverture d’un courriel
                            ne peut être considérée comme un contact émanant du prospect) ;
                        </li>
                    </ul>
                </ul>
                <p>Les données collectées via le chatbot sont conservées pendant 3 ans.</p>
                <ul>
                    <li>
                        Les données recueillies lors de l’envoi de la newsletter sont conservées jusqu’à la désinscription de l’utilisateur à la newsletter via le lien de désinscription en bas du
                        mail;
                    </li>
                    <li>
                        Les durées de conservation des cookies sont mentionnées dans la politique cookies (https://www.mail-certificate.fr/politique-cookies). Les données collectées par l’utilisateur
                        de cookies et de traceurs déposés sur vos terminaux n’excèderont pas une durée de douze (12) mois à compter du dépôt du cookie sur votre terminal. Au-delà des délais, vos
                        données seront supprimées et un nouveau consentement aux cookies vous sera demandé.
                    </li>
                </ul>
                <p>À l’expiration des durées de conservation, vos données sont supprimées de manière sécurisée et manuelle.</p>
                <h2>Vos droits en vertu de la législation européenne sur la protection des données</h2>
                <p>
                    Mail Certificate base ses activités de traitement de données sur le consentement de l'utilisateur, qui pourrait influencer les droits et libertés de ces derniers. Dans le cadre de
                    cette base légale, vous pouvez exercer plusieurs droits conformément à la législation européenne sur la protection des données :
                </p>
                <ul>
                    <li>
                        <strong>Droit de consultation</strong>: sans besoin de justifier une raison spécifique, vous pouvez demander à Mail Certificate d'accéder aux informations personnelles vous
                        concernant. De ce fait, chaque utilisateur a le droit de consulter les données collectées en contactant le service responsable de la protection des données;
                    </li>
                    <li>
                        <strong>Droit de correction de vos informations personnelles </strong>: lorsque vos données sont traitées, Mail Certificate met en place des mesures pour garantir leur
                        exactitude et leur mise à jour en fonction des objectifs pour lesquels elles ont été recueillies. Si vos informations personnelles sont incorrectes ou incomplètes, vous avez le
                        droit de demander leur rectification;
                    </li>
                    <li>
                        <strong>Droit à l'effacement de vos informations personnelles </strong>: dans certaines circonstances spécifiques, vous pouvez demander l'effacement de vos données
                        personnelles. Vous pouvez notamment exercer ce droit dans les situations suivantes :
                    </li>
                    <ul>
                        <li>Si vos informations sont utilisées à des fins de prospection;</li>
                        <li>Si vos informations ne sont plus nécessaires aux fins pour lesquelles elles ont été initialement collectées ou traitées;</li>
                        <li>Si vos informations font l’objet d’un traitement illicite;</li>
                        <li>Si vos informations doivent être effacées pour respecter une obligation légale.</li>
                    </ul>
                    <li>
                        <strong>Droit à la portabilité </strong>: vous avez le droit de récupérer une partie de vos informations personnelles dans un format ouvert et facilement lisible pour une
                        réutilisation personnelle;
                    </li>
                    <li>
                        <strong>Droit d'opposition ou droit de retrait du consentement </strong>: à tout moment, vous avez le droit de vous opposer au traitement de vos données et de retirer votre
                        consentement. Cependant, ce droit d'opposition n'est pas un droit à l'effacement total et définitif de toutes vos informations. La demande d'opposition ne sera pas satisfaite
                        si :
                    </li>
                    <ul>
                        <li>Il existe une ou plusieurs raisons légitimes et impératives de continuer à traiter vos informations;</li>
                        <li>Vos informations sont nécessaires pour établir, exercer ou défendre des droits en justice;</li>
                        <li>Une obligation légale impose de conserver vos informations.</li>
                    </ul>
                </ul>
                <p>
                    Pour toutes les demandes concernant l'exercice de vos droits, vous pouvez contacter Mail Certificate à l'adresse suivante :{" "}
                    <a href="mailto:contact@mail-certificate.com">contact@mail-certificate.com</a>{" "}
                </p>
                <h2>Droit de recours auprès de la CNIL</h2>
                <p>Vous avez le droit de déposer une plainte auprès de l'autorité de contrôle, la Commission Nationale de l'Informatique et des Libertés (CNIL).</p>
                <h2>Suppression de compte</h2>
                <p>
                    Vous pouvez à tout moment demander la suppression définitive de votre compte par mail a <a href="mailto:contact@mail-certificate.com">contact@mail-certificate.com</a>
                    <p>Voir les conditions d'application légales ci-dessus.</p>
                </p>
                <h2>Contacts et délégué à la protection des données</h2>
                <p>
                    La désignation d'un délégué à la Protection des Données démontre l'engagement de Mail Certificate à protéger, sécuriser et respecter la confidentialité des données personnelles de
                    ses clients.
                </p>

                <p>
                    Si vous avez des questions concernant cette politique de confidentialité ou les activités de traitement des données menées par Mail Certificate, ou si vous souhaitez exercer l'un
                    de vos droits en vertu du RGPD, veuillez nous contacter :
                </p>
                <ul>
                    <li>Par courrier :</li>
                    <ul>
                        <li>Mail Certificate</li>
                        <li>37 A Avenue Egle</li>
                        <li>78600 Maisons-Laffitte</li>
                    </ul>
                    <li>Par email :</li>
                    <ul>
                        <li>
                            <a href="mailto:contact@mail-certificate.com">contact@mail-certificate.com</a>
                        </li>
                    </ul>
                </ul>
                <h2>Modifications du document</h2>
                <p>Ce document de Mail Certificate est sujet à des modifications.</p>
                <br />
                <InternalAd />
            </Section>
        </Container>
    );
};

export default PolitiqueConfidentialite;
