import React, { useState, useRef } from "react";
import { colors } from "../utils/constants";
import styled from "styled-components";
import Card from "reactstrap/es/Card";
import { testFileType } from "../utils/utils";
import { Alert } from "reactstrap";
import BaseBlock from "./BaseBlock";

const Zone = styled(Card)`
  border-style: dashed;
  border-color: ${(props) => (props.dragging ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.1)")};
  width: 100%;
  opacity: ${(props) => (!!props.dragging ? 0.5 : 1)};
  text-align: center;
  padding: 40px;
  background-color: ${colors.primary};

  :hover {
    border-color: rgba(0, 0, 0, 0.05);
    opacity: 0.5;
    cursor: pointer;
  }
`;

const Dropzone = ({ setFiles, files, step, ...props }) => {
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  const _onFilesAdded = (evt) => {
    const files = evt.target.files;
    _saveFileList(files);
  };

  const _saveFileList = async (arr) => {
    let newPhotos = [...files];

    for (let i = 0; i < arr.length; i++) {
      if (!testFileType(arr[i].name)) {
        alert("Le format du fichier est invalide.");
        continue;
      }

      if (arr[i].size > 30 * 1e6) {
        alert("Le fichier est trop lourd");
        continue;
      }

      if (newPhotos.length >= 10) {
        break;
      }

      newPhotos.push(arr[i]);
    }

    setFiles(newPhotos);
  };

  const openFileDialog = () => fileInputRef.current.click();
  const onDragOver = (e) => e.preventDefault() || setDragging(true);
  const onDragLeave = (e) => e.preventDefault() || setDragging(false);
  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    _saveFileList(e.dataTransfer.files);
    setDragging(false);
  };

  const _toggleFile = (f) => {
    let newFiles = [...files];
    newFiles = newFiles.filter((filterable) => filterable !== f);
    setFiles(newFiles);
  };

  return (
    <div>
      {step === "REDACTION" && (
        <>
          <input ref={fileInputRef} style={{ display: "none" }} type="file" multiple onChange={_onFilesAdded} />
          <Zone
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={openFileDialog}
            dragging={dragging ? "1" : undefined}>
            <div>
              <i className={"ni ni-cloud-download-95"} style={{ fontSize: 32, color: "white" }} />
            </div>
            <b style={{ color: "white" }}>
              Cliquez ici ou glissez des fichiers pour les ajouter en{" "}
              <strong style={{ fontSize: 20, textDecoration: "underline" }}>pièce jointe</strong>
            </b>
            <small style={{ color: "white" }}>Jusqu'à 10 pièces jointes de 30Mo</small>
          </Zone>
        </>
      )}
      <BaseBlock>
        {files.map((f, i) => {
          return (
            <Alert toggle={() => _toggleFile(f)} className={"alert-inline mr-2"} key={f.name + "_" + i}>
              {f.name}
            </Alert>
          );
        })}
      </BaseBlock>
    </div>
  );
};

export default Dropzone;
