import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import { Form } from "reactstrap";
import InputGroup from "../InputGroup";
import Button from "../Button";
import { Row } from "../Grid";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const VerificationForm = ({ onSuccess, errors = {}, destination, recipient_id = false, recipient_token = false }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [code, setCode] = useState("");
  const [internalErrors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    api.post("/users/verificationschecks", { code, destination, recipient_id }).then((res) => {
      if (res.status === "approved") {
        onSuccess();
      } else {
        setErrors({ code: "Code invalide" });
      }
    });
  };

  const handleRedirectionCodeSend = async () => {
    if (recipient_id) {
      return history.push(`/reco/code/send/${recipient_id}/${recipient_token}`);
    }

    const repValidation = await api.post("/users/verifications", { destination });

    if (repValidation) {
      addToast("Le code de vérification a été envoyé.", { appearance: "success" });
    } else {
      addToast("Une erreur est survenue lors de l'envoi du code de vérification.", {
        appearance: "error",
      });
    }
  };

  let errorKeys = Object.keys(errors).toString();
  useEffect(() => {
    setErrors(errors === null ? {} : errors);
  }, [errorKeys]);

  return (
    <div>
      <label htmlFor="code" style={{ marginBottom: "10px" }}>
        Code de vérification:
      </label>

      <Form onSubmit={handleSubmit}>
        <InputGroup
          error={internalErrors.code}
          value={code}
          onChangeText={setCode}
          placeholder={"Code"}
          icon={"fa fa-lock"}
          name={"code"}
        />

        <Row md between>
          <Button left onClick={handleRedirectionCodeSend} color="danger" className="btn btn-sm">
            Renvoyer le code
          </Button>
          <div className="form-group">
            <Button right type={"submit"}>
              Continuer
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default VerificationForm;
