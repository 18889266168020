import React from "react";
import styled from "styled-components";
import { colors, contrastColors } from "../utils/constants";

const CommercialBannerContainer = styled.div`
  background-color: ${colors.primary};
  width: 100%;
  text-align: center;
  padding: 12px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CommercialBannerText = styled.p`
  color: ${contrastColors.primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  line-height: 1.4;
`;

const HighlightedText = styled.span`
  color: ${colors.accent};
  font-weight: 900;
`;

const DateText = styled.span`
  color: ${contrastColors.primary};
  font-size: 18px;
  font-weight: bold;
`;

const AmountExample = styled.div`
  color: ${contrastColors.primary};
  font-size: 16px;
  margin-top: 5px;
`;

const ExampleAmount = styled.span`
  color: ${contrastColors.primary};
  font-weight: bold;
`;

const CommercialBanner = () => (
  <CommercialBannerContainer>
    <CommercialBannerText>
      Offre <HighlightedText>Black Friday</HighlightedText> ! Du <DateText>20 au 30 novembre 2024</DateText>, pour tout achat de
      crédit au-dessus de 20€, nous vous créditons <HighlightedText>50%</HighlightedText> supplémentaire offert !{" "}
      <span role="img" aria-label="party">
        🎉
      </span>
    </CommercialBannerText>
    <AmountExample>
      Exemple : <ExampleAmount>50€</ExampleAmount> crédités → recevez <ExampleAmount>75€</ExampleAmount>
    </AmountExample>
  </CommercialBannerContainer>
);

export default CommercialBanner;
