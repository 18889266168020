import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";
import InternalAd from "../../components/blocks/InternalAd";

const Apropos = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Qui sommes-nous ?</title>
        <meta name={"description"} content={" Le site mail-certificate.com est édité par la société Mail-certificate."} />
      </Helmet>
      <Section top>
        <div className="hdrd">
          <h1>Qui sommes-nous ?</h1>
        </div>
        <p>Le site mail-certificate.com est édité par la société Mail-certificate.</p>

        <p>
          Après plus de 30 ans d'activité et de croissance rendue possible grâce à internet, son gérant souhaite valoriser son
          expérience du web dans le cadre d'un nouveau projet.
          <br />
          Amené à se déplacer régulièrement pour l’envoi de lettre recommandée papier réalisé dans le cadre de ses activités, il a
          naturellement souhaité apporter de la simplicité dans cette tâche. C'est désormais chose faite grâce au site
          mail-certificate.
        </p>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default Apropos;
