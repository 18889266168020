import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Section from "../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import VerificationForm from "../components/blocks/VerificationForm";
import { VerificationContext } from "../contexts/verification";
import api from "../utils/api";

const AcceptedWrapper = styled(Container)``;

const CodeEnter = ({ ...props }) => {
  const { destination } = useContext(VerificationContext);

  useEffect(() => {
    checkAccept();
  }, []);

  const checkAccept = async () => {
    if (props.match.params.recipient_id && props.match.params.recipient_token) {
      let recipient = await api.get(`/recipients/${props.match.params.recipient_id}/${props.match.params.recipient_token}`);

      if (recipient.status === "ACCEPTED") {
        goAccept();
      }
    }
  };

  const goAccept = () => {
    props.history.push(`/reco/accept/${props.match.params.recipient_id}/${props.match.params.recipient_token}`);
  };

  return (
    <AcceptedWrapper>
      <Section top>
        <Helmet>
          <title>Authentification</title>
          <meta name={"description"} content={"Acceptez vos courriers électroniques avec horodatage certifié eIDAS."} />
        </Helmet>
        <h1>Authentification</h1>
        <p>Veuillez entrer le code de vérification reçu par sms ou email.</p>
        <VerificationForm
          destination={destination}
          onSuccess={goAccept}
          recipient_id={props.match.params.recipient_id ?? false}
          recipient_token={props.match.params.recipient_token ?? false}
        />
      </Section>
    </AcceptedWrapper>
  );
};

export default CodeEnter;
