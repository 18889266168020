import React from "react";
import Container from "reactstrap/es/Container";

import Section from "../components/Section";
import SignupLogin, { SIGNUP } from "../components/blocks/SignupLogin";
import { Helmet } from "react-helmet/es/Helmet";

const Signup = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Inscription à Mail-Certificate l'envoi de mail recommandé avec horodatage certifié eIDAS</title>
        <meta
          name={"description"}
          content={"Inscription à Mail-Certificate l'envoi de mail recommandé avec horodatage certifié eIDAS"}
        />
      </Helmet>
      <Section top>
        <SignupLogin onLogin={() => props.history.push("/")} onSignup={() => props.history.push("/")} defaultState={SIGNUP} />
      </Section>
    </Container>
  );
};

export default Signup;
