import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import {EmailContext} from "../contexts/email";
import {ContentState, EditorState} from "draft-js";
import useElements from "../utils/useElement";
import htmlToDraft from 'html-to-draftjs';

const TemplatesModalWrapper = styled.div`

`;

const TemplatesModal = ({open, toggle, ...props}) => {
    const {content, setContent} = useContext(EmailContext);
    const [settingTemplate, setSettingTemplate] = useState(false);
    let [templates = [], loaded] = useElements('/mail_templates');

    const _setTemplate = t=>{
        let es =EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(t.content).contentBlocks
            )
        );

       setContent(es);
       toggle(t.id);
    };

    const _clickTemplate = t => {
        if(content.getCurrentContent().hasText()){
            setSettingTemplate(t);
        }else{
            _setTemplate(t);
        }
    };

    const _confirmSet = () => {
        _setTemplate(settingTemplate);
        setSettingTemplate(false);
    };

    const _cancelSet = () => {
        setSettingTemplate(false);
    };

    return <TemplatesModalWrapper>
        <Modal isOpen={open} toggle={toggle}>
            {
                settingTemplate?
                    <>
                        <ModalHeader
                            style={{border:'none'}}
                            toggle={toggle}>{settingTemplate.name}</ModalHeader>
                        <ModalBody>
                            Choisir un nouveau modèle supprimera le texte déjà entré. Souhaitez-vous continuer ?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={_cancelSet}>Annuler</Button>
                            <Button color="success" onClick={_confirmSet}>Confirmer</Button>
                        </ModalFooter>
                    </>
                    :
                    <>
                        <ModalHeader
                            style={{border:'none'}}
                            toggle={toggle}>Choisir un modèle</ModalHeader>
                        <ModalBody>
                            <ListGroup>
                                {
                                    loaded&&templates.map(t=><ListGroupItem
                                        key={t.title}
                                        action
                                        onClick={()=>_clickTemplate(t)}
                                    >
                                        {t.title}
                                    </ListGroupItem>)
                                }
                            </ListGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggle}>Annuler</Button>
                        </ModalFooter>
                    </>
            }

        </Modal>
    </TemplatesModalWrapper>;
};

export default TemplatesModal;