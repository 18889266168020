import React, {useEffect, useState} from "react";
import api from "./api";
import {useToasts} from "react-toast-notifications";
var fs = undefined;
if(typeof window === 'undefined'){
    fs = require('fs');
    var path = require("path");
}

const fetchSSR = cachedId => {
    try{
        var contents = fs.readFileSync( path.resolve(__dirname, "../../cached/blogposts.json"), 'utf8');
        if(cachedId&&cachedId==='ALL')
            return JSON.parse(contents);
        if(cachedId)
            return (JSON.parse(contents)).find(article=>article.slug===cachedId);
    }catch (e) {
        return [];
    }
};

const useCached = (path, data = {}, cachedId = false) => {
    const [elements, setElements] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const {addToast} = useToasts();

    useEffect(()=>{
        _fetch();
    }, []);

    if(typeof fs!=='undefined')
        return [fetchSSR(cachedId), true, ()=>null];

    const _fetch = async () => {
        let rep = await api.get(path, data);
        if(!rep.error_message)
            setElements(rep);
        else
            addToast(rep.error_message, { appearance: 'error' });
        setLoaded(true);
    };

    return [elements, loaded, _fetch];
};

export default useCached;